import CommonTable from "components/Tables/CommonTable";
import { Col, Input, Row, Spin, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { renderLoader } from "utils/helpers/helpers";
import style from "./style.module.scss";
import { ColTextCheck } from "components/export-common-components/table-columns-text-check";
import classNames from "classnames";
import { CheckOutlined, CloseOutlined, EyeOutlined } from "@ant-design/icons";
import { getNewAccountsRequestApi } from "apis/accounts-request";
import { updateAuctionActionApi } from "apis/accounts-request";
import { NotificationManager } from "react-notifications";
import CustomModal from "components/CustomModal/CustomModal";
import { renderItemDataOrEmptyNull } from "utils/common-functions";
import { EMPTY_NULL_DATA } from "utils/constants/const";
import { renderStatusBackgroundColor } from "utils/common-functions";

const NewAccountsRequestList = (props) => {
  const [page, setPage] = useState(1);
  const [
    originalAccountRequestListApiRes,
    setOriginalAccountRequestListApiRes,
  ] = useState(null);
  const [accountsRequestListData, setAccountsRequestListData] = useState(null);
  const [accountRequestRes, setAccountRequestRes] = useState(null);
  const [loading, setLoading] = useState(false);
  const [visibleDetailsModal, setVisibleDetailsModal] = useState(false);
  const [rowData, setRowData] = useState(null);

  const fetchDataApi = async () => {
    try {
      setLoading(true);
      const response = await getNewAccountsRequestApi(page);
      if (response.status === 200 && response.data?.data) {
        setAccountsRequestListData(response.data.data);
        setAccountRequestRes(response?.data);
        setOriginalAccountRequestListApiRes(response?.data?.data);
      } else {
        throw response;
      }
    } catch (e) {
      console.log({ e });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataApi();
  }, []);

  useEffect(() => {
    fetchDataApi();
  }, [page]);

  const onDetailsClick = (data) => {
    setRowData(data);
    setVisibleDetailsModal(true);
  };

  const onUpdateAuctionAction = async (id, action) => {
    try {
      const payload = { userId: id, action };
      const response = await updateAuctionActionApi(payload);
      if (response.status === 200 && response.data) {
        NotificationManager.info(response?.data?.message);
        fetchDataApi();
      } else {
        throw response;
      }
    } catch (e) {
      console.log({ e });
    }
  };

  const columnsData = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      render: (text, row) => {
        return (
          <p className={style.onClickName} onClick={() => onDetailsClick(row)}>
            {ColTextCheck(text)}
          </p>
        );
      },
    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
      render: ColTextCheck,
    },
    {
      title: "Phone No.",
      key: "phoneNo",
      dataIndex: "phoneNo",
      render: ColTextCheck,
    },
    {
      title: "Business Name",
      key: "email",
      dataIndex: "email",
      render: ColTextCheck,
    },
    {
      title: "Business License",
      key: "license",
      dataIndex: "license",
      render: (text) => {
        return (
          <>
            {(text && (
              <a href={text} target="_blank" rel="noopener noreferrer">
                <img width={40} src={text} alt="icon" />
              </a>
            )) || <p>{EMPTY_NULL_DATA}</p>}
          </>
        );
      },
    },
    {
      title: "Photo",
      key: "photo",
      dataIndex: "photo",
      render: (text) => {
        return (
          <>
            {(text && (
              <a href={text} target="_blank" rel="noopener noreferrer">
                <img width={40} src={text} alt="icon" />
              </a>
            )) || <p>{EMPTY_NULL_DATA}</p>}
          </>
        );
      },
    },
    {
      title: "Actions",
      key: "Actions",
      dataIndex: "Actions",
      render: (text, row) => {
        return (
          <div className={style.actions}>
            <Row justify={"space-between"} style={{ width: "40px" }}>
              <Tooltip placement="top" title={"Approve"}>
                <CheckOutlined
                  style={{
                    color: renderStatusBackgroundColor("approved"),
                  }}
                  onClick={() => onUpdateAuctionAction(row?.id, "approved")}
                />
              </Tooltip>
              <Tooltip placement="top" title={"Decline"}>
                <CloseOutlined
                  onClick={() => onUpdateAuctionAction(row?.id, "blocked")}
                  style={{
                    color: renderStatusBackgroundColor("Decline"),
                  }}
                />
              </Tooltip>
            </Row>
            <div onClick={() => {}}>
              <EyeOutlined
                className={style.eyeIcon}
                onClick={() => onDetailsClick(row)}
              />
            </div>
          </div>
        );
      },
    },
  ];

  const onChangeSearch = (e) => {
    const dummyArr = originalAccountRequestListApiRes?.slice();
    const filteredData = dummyArr?.filter((item) =>
      (item?.name).toLowerCase()?.includes(e.target.value.toLowerCase())
    );
    if (filteredData?.length) {
      setAccountsRequestListData(filteredData);
    } else if (e.target.value === "") {
      setAccountsRequestListData(originalAccountRequestListApiRes);
    } else {
      setAccountsRequestListData([]);
    }
  };

  return (
    <div className={classNames(style.userListContainer, "both-side-padding")}>
      <Spin spinning={loading} indicator={renderLoader}>
        <Row
          justify={"end"}
          alignItem="center"
          className={style.searchBoxContainer}
        >
          <Input
            placeholder="Search Accounts Request"
            onChange={onChangeSearch}
            style={{ width: "22%", height: "40px", fontSize: "15px" }}
          />
        </Row>

        <CommonTable
          className={style.customerTable}
          data={accountsRequestListData}
          fetchRecords={(page) => setPage(page)}
          totalRecord={accountRequestRes?.total}
          columns={columnsData}
        />

        <CustomModal
          visibility={visibleDetailsModal}
          handleCancel={() => setVisibleDetailsModal(false)}
          handleOk={() => null}
          title={"Details"}
          className={style.detailsModal}
        >
          <Spin spinning={loading} indicator={renderLoader}>
            <Row className={style.modalHeading}>
              <Col span={12}>
                <p> Name </p>
              </Col>
              <Col span={12}>
                {" "}
                <p> {renderItemDataOrEmptyNull(rowData?.name)} </p>{" "}
              </Col>
              <Col span={12}>
                <p> Email </p>
              </Col>
              <Col span={12}>
                {" "}
                <p> {renderItemDataOrEmptyNull(rowData?.email)} </p>{" "}
              </Col>
              <Col span={12}>
                <p> Phone Number </p>
              </Col>
              <Col span={12}>
                {" "}
                <p> {renderItemDataOrEmptyNull(rowData?.phoneNo)} </p>{" "}
              </Col>
              <Col className={style.docs} span={12}>
                <p> Business License </p>
              </Col>
              <Col span={12}>
                {rowData?.license &&
                  ((
                    <a
                      href={rowData?.license || ""}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={rowData?.license} alt="" />
                    </a>
                  ) || <p> {EMPTY_NULL_DATA} </p>)}
              </Col>
              <Col className={style.docs} span={12}>
                <p> Photo </p>
              </Col>
              <Col span={12}>
                {(rowData?.photo && (
                  <a
                    href={rowData?.photo || ""}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={rowData?.photo} alt="" />
                  </a>
                )) || <p> {EMPTY_NULL_DATA} </p>}
              </Col>
              <Col span={12}>
                <p> Country </p>
              </Col>
              <Col span={12}>
                {" "}
                <p> {renderItemDataOrEmptyNull(rowData?.country?.name)} </p>{" "}
              </Col>
              <Col span={12}>
                <p> Business Name </p>
              </Col>
              <Col span={12}>
                {" "}
                <p> {renderItemDataOrEmptyNull(rowData?.email)} </p>{" "}
              </Col>
              <Col span={12}>
                <p> Business Address </p>
              </Col>
              <Col span={12}>
                {" "}
                <p>
                  {" "}
                  {renderItemDataOrEmptyNull(rowData?.businessAddress)}{" "}
                </p>{" "}
              </Col>
            </Row>
          </Spin>
        </CustomModal>
      </Spin>
    </div>
  );
};

export default NewAccountsRequestList;
