import React, { useEffect, useState } from "react";
import style from "./style.module.scss";
import CustomButton from "components/custom-button";
import { Col, Form, Image, Input, Row, Spin, Tabs } from "antd";
import EmptyImg from "assets/img/default.png";
import { renderItemDataOrEmptyNull } from "utils/common-functions";
import { getManageAuctionsByIdApi } from "apis/auction/AuctionApi";
import { requestErrorHandel } from "utils/helpers/helpers";
import { renderLoader } from "utils/helpers/helpers";
import { findGrade } from "utils/common-functions";
import CustomModal from "components/CustomModal/CustomModal";
import { setRules } from "utils/common-functions";
import { cancelAuctionApi } from "apis/auction/AuctionApi";
import { successMessage } from "utils/helpers/helpers";
import { sortArrCompareMaxValues } from "utils/common-functions";
import { postSetWinnerApi } from "apis/bids";
import { addAuctionShipmentApi } from "apis/auction/AuctionApi";
import { EMPTY_NULL_DATA } from "utils/constants/const";
import { formatDate } from "utils/common-functions";
import { getTimeFromdateString } from "utils/common-functions";
import { renderStatusTag } from "components/export-common-components/table-columns-text-check";
import NoDataFound from "components/no-data-found";
import { CloseOutlined, EditOutlined } from "@ant-design/icons";
import { getTimeAMPMFromDate } from "utils/common-functions";
import { CURRENCY_SYMBOL } from "utils/constants/const";
import AuctionImageViewer from "components/AuctionImages/AuctionImages";

const AuctionDetail = (props) => {
  const [form] = Form.useForm();
  const [formShipment] = Form.useForm();

  const { location } = props;
  const auctionId = location.state;
  const [isActionable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [singleAuctionData, setSingleAuctionData] = useState(null);
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleShipmentModal, setVisibleShipmentModal] = useState(false);
  const [visibleWinnerModal, setVisibleWinnerModal] = useState(false);
  const [allBidsData, setAllBidsData] = useState([]);
  const [showAuctionGallery, setShowAuctionGallery] = useState(false);

  useEffect(() => {
    if (auctionId) {
      fetchSingleAuction(auctionId);
    }
  }, [auctionId]);

  useEffect(() => {
    if (singleAuctionData) {
      setAllBidsData(
        singleAuctionData?.bids?.sort((a, b) =>
          sortArrCompareMaxValues(a, b, "amount")
        )
      );

      formShipment.setFieldsValue({
        shipmentLink: singleAuctionData?.shipmentLink,
        trackingNo: singleAuctionData?.trackingNo,
      });
    }
  }, [singleAuctionData]);

  const fetchSingleAuction = async (id) => {
    let res = null;
    try {
      setLoading(true);
      const response = await getManageAuctionsByIdApi(id);
      setLoading(false);
      res = response.data?.data;
      if (response.status === 200 && response.data?.data) {
        setSingleAuctionData(response.data?.data);
      }
    } catch (e) {
      setLoading(false);
      requestErrorHandel({ error: e });
    } finally {
      setLoading(false);
      return res;
    }
  };

  const onSetWinnerBid = async (id) => {
    let res = null;
    try {
      setLoading(true);
      const response = await postSetWinnerApi({ auctionId, bidId: id });
      setLoading(false);
      res = response.data?.data;
      if (response.status === 200) {
        await fetchSingleAuction(auctionId);
        setVisibleWinnerModal(false);
        successMessage({ message: "Bid winner has been announced." });
      }
    } catch (e) {
      setLoading(false);
      requestErrorHandel({ error: e });
    } finally {
      setLoading(false);
      return res;
    }
  };

  const descriptionItems = [
    {
      key: "1",
      label: `EN`,
      children: (
        <>
          <p>
            <b>Description: </b>
            {renderItemDataOrEmptyNull(
              singleAuctionData?.auctionTranslation?.find(
                (item) => item.languageCode === "en"
              ).description
            )}
          </p>
          {/* <p>Manufactor: Manufacture Name</p>
          <p>Category: </p>
          <p>Grade: A</p>
          <p>Year: 2022</p> */}
        </>
      ),
    },
    {
      key: "2",
      label: `CH`,
      children: (
        <>
          <p>
            <b>Description: </b>
            {renderItemDataOrEmptyNull(
              singleAuctionData?.auctionTranslation?.find(
                (item) => item.languageCode === "ch"
              ).description
            )}
          </p>
          {/* <p>Manufactor: Manufacture Name</p>
          <p>Category: </p>
          <p>Grade: A</p>
          <p>Year: 2022</p> */}
        </>
      ),
    },
  ];

  const temsConditionItems = [
    {
      key: "1",
      label: `EN`,
      children: (
        <>
          <p>
            <b>Terms & Conditions: </b>
            {renderItemDataOrEmptyNull(
              singleAuctionData?.auctionTranslation?.find(
                (item) => item.languageCode === "en"
              ).termConditions
            )}
          </p>
        </>
      ),
    },
    {
      key: "4",
      label: `CH`,
      children: (
        <>
          <p>
            <b>Terms & Conditions: </b>
            {renderItemDataOrEmptyNull(
              singleAuctionData?.auctionTranslation?.find(
                (item) => item.languageCode === "ch"
              ).termConditions
            )}
          </p>
        </>
      ),
    },
  ];

  const onConfirmCancelAuction = async (values) => {
    await onCancelAuction(values);
  };

  const onCancelAuction = async (payload) => {
    let res = null;
    try {
      setLoading(true);
      const response = await cancelAuctionApi(auctionId, payload);
      setLoading(false);
      res = response.data?.data;
      if (response.status === 200 && response.data?.data) {
        await fetchSingleAuction(auctionId);
        setVisibleModal(false);
        successMessage({ message: "Auction cancelled successfully" });
      }
    } catch (e) {
      setLoading(false);
      requestErrorHandel({ error: e });
    } finally {
      setLoading(false);
      return res;
    }
  };

  const winnerModal = (
    <CustomModal
      visibility={visibleWinnerModal}
      handleCancel={() => setVisibleWinnerModal(false)}
      className={style.winnerModal}
      handleOk={() => {}}
      title={"Placed bids"}
    >
      <div className={style.commonDeleteModalContainer}>
        <Spin spinning={loading} indicator={renderLoader}>
          <Row className={style?.heading}>
            <h4>Announce Winner</h4>
          </Row>
          <div>
            <Row>
              <Col span={8}>
                <h5>Name</h5>
              </Col>
              <Col span={8}>
                <h5>Amount</h5>
              </Col>
              <Col span={8}>
                <h5>Winner TBD</h5>
              </Col>
            </Row>
            {allBidsData?.map((item) => {
              return (
                <Row>
                  <Col span={8}>
                    <p>{renderItemDataOrEmptyNull(item?.name)} </p>
                  </Col>
                  <Col span={8}>
                    <p>
                      {CURRENCY_SYMBOL}
                      {renderItemDataOrEmptyNull(item?.amount)}
                    </p>
                  </Col>
                  <Col span={8}>
                    {(item?.identifier === "B-0001" ||
                      item?.identifier === "B-0002") && (
                      <CustomButton
                        onClick={() => onSetWinnerBid(item?.id)}
                        title={"Winner"}
                        disabled={item?.identifier === "B-0002" ? true : false}
                      />
                    )}
                  </Col>
                </Row>
              );
            })}
          </div>
        </Spin>
      </div>
    </CustomModal>
  );

  const cancelAuctionModal = (
    <CustomModal
      visibility={visibleModal}
      handleCancel={() => {
        setVisibleModal(false);
      }}
      handleOk={() => {}}
      className={style.modalCancelAuction}
      title={"Confirmation Alert"}
    >
      <Form
        layout="vertical"
        className={style.cancelModalForm}
        form={form}
        onFinish={onConfirmCancelAuction}
      >
        <Row>
          <p style={{ marginLeft: 2 }}>
            Are you sure you want to cancel this auction? It will be removed if
            there is any winner.
          </p>
        </Row>
        <div className={style.reasonInputContainer}>
          <span> 120 </span>
          <Form.Item
            rules={setRules("Reason for cancel auction")}
            label={"Reason for cancelling"}
            name={"reason"}
          >
            <Input />
          </Form.Item>
        </div>

        <CustomButton
          className={style.btn}
          loading={loading}
          title={"Submit"}
          htmlType="submit"
        />
        <CustomButton
          className={style.btn}
          onClick={() => setVisibleModal(false)}
          title={"Cancel"}
        />
      </Form>
    </CustomModal>
  );

  const onSubmitShipment = async (values) => {
    let res = null;
    try {
      setLoading(true);
      const response = await addAuctionShipmentApi({
        auctionId,
        ...values,
      });
      setLoading(false);
      res = response.data?.data;
      if (response.status === 200) {
        await fetchSingleAuction(auctionId);
        setVisibleShipmentModal(false);
        successMessage({ message: "Shipment details has been added." });
      }
    } catch (e) {
      setLoading(false);
      requestErrorHandel({ error: e });
    } finally {
      setLoading(false);
      return res;
    }
  };

  console.log("singleAuctionID", singleAuctionData);

  return (
    <div className={style.auctionDetailsContainer}>
      <Spin spinning={loading} indicator={renderLoader}>
        {cancelAuctionModal}
        {winnerModal}
        {singleAuctionData?.gallery.length > 0 && (
          <AuctionImageViewer
            images={singleAuctionData?.gallery}
            show={showAuctionGallery}
            toggleShow={setShowAuctionGallery}
          />
        )}
        {isActionable && (
          <Row justify={"end"} className={style.topBtnContainer}>
            <CustomButton
              onClick={() => setVisibleModal(true)}
              title={"Cancel"}
              startData={<CloseOutlined />}
              variant="two"
            />
            <CustomButton
              onClick={() => {
                props.history.push("manage-auctions/create-auction", {
                  id: auctionId,
                });
              }}
              title={"Edit"}
              startData={<EditOutlined />}
              variant="one"
            />
          </Row>
        )}
        <Row gutter={40}>
          <Col md={10} sm={24} xs={24}>
            <div className={style.boxContainer}>
              <Image
                width={"10rem"}
                src={
                  (singleAuctionData?.gallery?.length > 0 &&
                    singleAuctionData?.gallery[0]?.file) ||
                  EmptyImg
                }
                alt="img"
              />
              {singleAuctionData?.gallery?.length > 1 && (
                <div className={style.imgLength}>
                  <p onClick={() => setShowAuctionGallery(true)}>
                    +{singleAuctionData?.gallery.length - 1}
                  </p>
                </div>
              )}
            </div>

            <div className={style.boxContainer}>
              <div className={style.textBoxContainer}>
                <Tabs defaultActiveKey="1" items={descriptionItems} />
              </div>
            </div>

            <div className={style.boxContainer}>
              <div className={style.textBoxContainer}>
                <Tabs defaultActiveKey="1" items={temsConditionItems} />
              </div>
            </div>
          </Col>
          <Col className={style.rightCol} md={14} sm={24} xs={24}>
            {singleAuctionData?.winner && (
              <Row className={style.maxBidRow}>
                <Row
                  flex="row"
                  style={{ width: "100%", marginBottom: "20px" }}
                  justify={"space-between"}
                >
                  <div>
                    <h4>
                      {renderItemDataOrEmptyNull(
                        singleAuctionData?.winner?.name
                      )}
                    </h4>
                    <p>Winner</p>
                    <p>
                      Payment Status: {singleAuctionData?.winner?.accountStatus}
                    </p>
                  </div>
                  <div>
                    <h4>
                      {renderItemDataOrEmptyNull(
                        singleAuctionData?.winner?.bidPrice
                      )}
                    </h4>
                  </div>
                </Row>
                <Row
                  flex="row"
                  style={{ width: "100%" }}
                  justify={"space-between"}
                >
                  <div>
                    <p>
                      Email:{" "}
                      {renderItemDataOrEmptyNull(
                        singleAuctionData?.winner?.email
                      )}
                    </p>
                    <p>
                      Phone Number:{" "}
                      {singleAuctionData?.winner?.phoneNo || EMPTY_NULL_DATA}
                    </p>
                    <p>
                      Country:{" "}
                      {renderItemDataOrEmptyNull(
                        singleAuctionData?.winner?.country?.name
                      )}
                    </p>
                  </div>
                  <p>
                    Date: {formatDate(singleAuctionData?.winner?.createdAt)}{" "}
                  </p>
                </Row>
                <Row
                  flex="row"
                  style={{ width: "100%" }}
                  justify={"space-between"}
                  align={"middle"}
                >
                  <p>
                    Business Address:{" "}
                    {renderItemDataOrEmptyNull(
                      singleAuctionData?.winner?.businessAddress
                    )}
                  </p>
                  <h4
                    className={style.seeUserDetails}
                    onClick={() => {
                      props.history.push("customers/user-details", {
                        id: singleAuctionData?.winner?.userId,
                      });
                    }}
                  >
                    See User Details
                  </h4>
                </Row>
              </Row>
            )}
            <Row className={style.headingRow}>
              <Col md={18} sm={24} xs={24}>
                <h2>
                  {renderItemDataOrEmptyNull(
                    singleAuctionData?.auctionTranslation?.find(
                      (item) => item.languageCode === "en"
                    ).title
                  )}
                </h2>
              </Col>
              <Col md={6} sm={24} xs={24}>
                <Row justify={"end"}>
                  {renderStatusTag(singleAuctionData?.status)}
                </Row>
              </Col>
            </Row>

            <Row
              className={style.auctionRow}
              justify={"space-between"}
              align={"center"}
            >
              <p>
                Auction ID: <span> {singleAuctionData?.identifier} </span>
              </p>
              <h6>Featured Auction</h6>
            </Row>
            <div className={style.cardsAuctionRow} gutter={20}>
              <Row gutter={10} style={{ marginBottom: "20px" }}>
                <Col md={4} sm={12} xs={24}>
                  <p>Number of Bids</p>
                  <h5>
                    {" "}
                    {renderItemDataOrEmptyNull(
                      singleAuctionData?.totalBids
                    )}{" "}
                  </h5>
                </Col>
                <Col md={8} sm={12} xs={24}>
                  <p>End Date & Time</p>
                  <h5>
                    {" "}
                    {formatDate(singleAuctionData?.endAt)} |{" "}
                    {getTimeAMPMFromDate(singleAuctionData?.endAt)}{" "}
                  </h5>
                </Col>
                <Col md={6} sm={12} xs={24}>
                  <p>Min Increment</p>
                  <h5>
                    {CURRENCY_SYMBOL}
                    {renderItemDataOrEmptyNull(singleAuctionData?.minIncrement)}
                  </h5>
                </Col>
                <Col md={6} sm={12} xs={24}>
                  <p>Current Price</p>
                  <h5>
                    {" "}
                    {CURRENCY_SYMBOL}
                    {renderItemDataOrEmptyNull(singleAuctionData?.price)}{" "}
                  </h5>
                </Col>
              </Row>
              <Row gutter={10}>
                <Col md={4} sm={12} xs={24}>
                  <p>Grade</p>
                  <h5>
                    {" "}
                    {renderItemDataOrEmptyNull(
                      findGrade(singleAuctionData?.gradeId)
                    )}{" "}
                  </h5>
                </Col>
                <Col md={8} sm={12} xs={24}>
                  <p>No. of pieces</p>
                  <h5>
                    {renderItemDataOrEmptyNull(singleAuctionData?.totalUnits)}
                  </h5>
                </Col>
                <Col md={6} sm={12} xs={24}>
                  <p>Price/Kg</p>
                  {renderItemDataOrEmptyNull(
                    singleAuctionData?.price / singleAuctionData?.totalUnits
                  )}
                </Col>
                <Col md={6} sm={12} xs={24}>
                  <p>Weight (Kg)</p>
                  <h5>
                    {renderItemDataOrEmptyNull(singleAuctionData?.weight)}
                  </h5>
                </Col>
              </Row>
            </div>

            {/* <Row gutter={5} className={style.bidAmount}>
              <p>
                Min bid amount:
                <span>
                  {renderItemDataOrEmptyNull(
                    singleAuctionData?.price + singleAuctionData?.minIncrement
                  )}
                  {"  "}
                  {CURRENCY_ABBREVIATION}
                </span>
                <InfoCircleOutlined />
              </p>
              <p>
                Proxy Bid:
                <span>
                  {singleAuctionData?.isProxy ? "Enabled" : "Disabled"}{" "}
                </span>
              </p>
            </Row> */}

            <Row className={style.allBidCard}>
              <Row className={style.bidHeadingRow}>
                <h4>All Bids</h4>
                {!singleAuctionData?.winner && allBidsData?.length > 0 && (
                  <CustomButton
                    onClick={async () => {
                      setVisibleWinnerModal(true);
                    }}
                    title="Winner TBD"
                  />
                )}
              </Row>
              {(allBidsData?.length &&
                allBidsData?.map((item) => {
                  return (
                    <Row className={style.bidListRow} justify={"middle"}>
                      <h6>
                        {" "}
                        {renderItemDataOrEmptyNull(item?.name)}{" "}
                        {(item?.identifier === "B-0003" ||
                          item?.identifier === "B-0002") && (
                          <>
                            {" "}
                            <br /> <p style={{ color: "red" }}>
                              Is winner
                            </p>{" "}
                          </>
                        )}{" "}
                      </h6>
                      <Row
                        justify={"space-between"}
                        align={"middle"}
                        gutter={20}
                      >
                        {item?.identifier === "B-0003" ? (
                          singleAuctionData?.shipmentLink ? (
                            <Row gutter={10} align={"middle"}>
                              <a
                                href={
                                  singleAuctionData?.shipmentLink +
                                  singleAuctionData?.trackingNo
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                                className={style.underlinedText}
                              >
                                Track Shipment
                              </a>
                              <EditOutlined
                                style={{ marginLeft: "5px" }}
                                onClick={() => setVisibleShipmentModal(true)}
                              />
                            </Row>
                          ) : (
                            <p
                              className={style.underlinedText}
                              onClick={() => setVisibleShipmentModal(true)}
                            >
                              {singleAuctionData?.shipmentLink ? (
                                <CustomButton title={"Update Tracking ID"} />
                              ) : (
                                <CustomButton title={"Add Tracking ID"} />
                              )}{" "}
                            </p>
                          )
                        ) : null}

                        <h6 style={{ marginLeft: "10px" }}>
                          {" "}
                          {CURRENCY_SYMBOL}
                          {renderItemDataOrEmptyNull(item?.amount)}
                        </h6>
                      </Row>
                    </Row>
                  );
                })) || <NoDataFound />}
            </Row>

            <Row className={style.auctionHistory}>
              {singleAuctionData?.history?.length > 0 && (
                <h2>Auction History</h2>
              )}
              {singleAuctionData?.history?.map((item) => {
                return (
                  <Row gutter={10}>
                    <Col
                      className={style.leftHistoyCol}
                      md={18}
                      sm={12}
                      xs={24}
                    >
                      <p>
                        <b>{item.key}: </b>{" "}
                        {(item?.key === "auctionWon" &&
                          `${
                            item?.logs?.winnerName
                          } has won this auction ${renderItemDataOrEmptyNull(
                            item?.logs?.auctionName
                          )}`) ||
                          (item?.key === "payAuction" &&
                            `${EMPTY_NULL_DATA} has paid the ${renderItemDataOrEmptyNull(
                              item?.logs?.auctionName
                            )}`) ||
                          (item?.key === "auctionClosed" &&
                            ` has closed the ${renderItemDataOrEmptyNull(
                              item?.logs?.auctionName
                            )}`)}
                      </p>
                    </Col>
                    <Col
                      className={style.rightHistoyCol}
                      md={6}
                      sm={12}
                      xs={24}
                    >
                      <p>{getTimeFromdateString(item?.logs?.date)}</p>
                      <b>{formatDate(item?.logs?.date)}</b>
                    </Col>
                  </Row>
                );
              })}
              {/* <Row gutter={10}>
                <Col className={style.leftHistoyCol} md={18} sm={12} xs={24}>
                  <p>Rehan Ahmed won the auction.</p>
                </Col>
                <Col className={style.rightHistoyCol} md={6} sm={12} xs={24}>
                  <p>12:35 pm</p>
                  <b>18-12-23</b>
                </Col>
              </Row> */}
            </Row>
          </Col>
        </Row>
        <CustomModal
          visibility={visibleShipmentModal}
          handleCancel={() => {
            setVisibleShipmentModal(false);
          }}
          handleOk={() => null}
          title={"Add Shipment Details"}
        >
          <Spin spinning={loading} indicator={renderLoader}>
            <Form
              layout="vertical"
              className={style.modalForm}
              form={formShipment}
              name="control-hooks"
              onFinish={onSubmitShipment}
            >
              <Row gutter={[10]}>
                <Col span={24}>
                  <Form.Item
                    rules={setRules("Shipment Link")}
                    label={"Shipment Link"}
                    name={"shipmentLink"}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    rules={setRules("Tracking No")}
                    label={"Tracking No"}
                    name={"trackingNo"}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Row justify={"center"}>
                    <CustomButton
                      className={style.btn}
                      title={"Save"}
                      disabled={loading}
                      htmlType="submit"
                      loading={loading}
                      destroyOnClose={true}
                    />
                  </Row>
                </Col>
              </Row>
            </Form>
          </Spin>
        </CustomModal>
      </Spin>
    </div>
  );
};

export default AuctionDetail;
