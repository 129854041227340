import CommonTable from "components/Tables/CommonTable";
import { Input, Row, Spin, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { renderLoader } from "utils/helpers/helpers";
import style from "./style.module.scss";
import { ColTextCheck } from "components/export-common-components/table-columns-text-check";
import classNames from "classnames";
import { renderItemDataOrEmptyNull } from "utils/common-functions";
import { capitalizeString } from "utils/common-functions";
import { getAllBidsApi } from "apis/bids";
import { formatDate } from "utils/common-functions";
import { getTimeFromOperationsWithDays } from "utils/common-functions";
import { EyeOutlined } from "@ant-design/icons";
import { CURRENCY_SYMBOL } from "utils/constants/const";

const BidsList = (props) => {
  const [page, setPage] = useState(1);
  const [originalBidsApiRes, setOriginalBidsListApiRes] = useState(null);
  const [bidsListData, setBidsListData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [totalNumberOfRecords, setTotalNumberOfRecords] = useState(0);

  const fetchDataApi = async () => {
    try {
      setLoading(true);
      const response = await getAllBidsApi(page);
      if (response.status === 200 && response.data?.data) {
        setBidsListData(response.data.data);
        setTotalNumberOfRecords(response.data?.total);
        setOriginalBidsListApiRes(response?.data?.data);
      } else {
        throw response;
      }
    } catch (e) {
      console.log({ e });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataApi();
  }, []);

  useEffect(() => {
    fetchDataApi();
  }, [page]);

  const columnsData = [
    {
      title: "User",
      key: "name",
      dataIndex: "name",
      render: (text) => {
        return (
          <span> {capitalizeString(renderItemDataOrEmptyNull(text))} </span>
        );
      },
    },
    {
      title: "Auction",
      key: "auctionName",
      dataIndex: "auctionName",
      render: (text, row) => {
        return (
          <div
            onClick={() => {
              props.history.push("auction-detail", row?.auctionId);
            }}
            className={style.onClickName}
          >
            <p>{ColTextCheck(text)}</p>
            <span> Auction Id: {row?.identifier} </span>
          </div>
        );
      },
    },
    {
      title: "Bidding Amount",
      key: "amount",
      dataIndex: "amount",
      render: (text) => {
        return (
          <Row className={style.amount} alignItem={"center"}>
            <p>{CURRENCY_SYMBOL}</p>
            {ColTextCheck(text)}
          </Row>
        );
      },
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (text) => {
        return (
          <span> {capitalizeString(renderItemDataOrEmptyNull(text))} </span>
        );
      },
    },
    {
      title: "Date",
      key: "date",
      dataIndex: "date",
      render: (text) => {
        return <span>{renderItemDataOrEmptyNull(formatDate(text))}</span>;
      },
    },
    {
      title: "Time Remaining",
      key: "endTime",
      dataIndex: "endTime",
      render: (text) => {
        return (
          <span>
            {renderItemDataOrEmptyNull(
              getTimeFromOperationsWithDays(text, new Date(), "-")
            )}
          </span>
        );
      },
    },
    {
      title: "Actions",
      key: "Actions",
      dataIndex: "Actions",
      render: (text, row) => {
        return (
          <Row justify={"center"} className={style.actions}>
            <Tooltip placement="top" title={"View"}>
              <EyeOutlined
                style={{
                  color: "#899499",
                  fontSize: "15px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  props.history.push("auction-detail", row?.auctionId);
                }}
              />
            </Tooltip>
          </Row>
        );
      },
    },
  ];

  const onChangeSearch = (e) => {
    const dummyArr = originalBidsApiRes?.slice();
    const filteredData = dummyArr?.filter((item) =>
      (item?.name).toLowerCase()?.includes(e.target.value.toLowerCase())
    );
    if (filteredData?.length) {
      setBidsListData(filteredData);
    } else if (e.target.value === "") {
      setBidsListData(setOriginalBidsListApiRes);
    } else {
      setBidsListData([]);
    }
  };

  return (
    <div className={classNames(style.userListContainer, "both-side-padding")}>
      <Spin spinning={loading} indicator={renderLoader}>
        <Row
          justify={"end"}
          alignItem="center"
          className={style.searchBoxContainer}
        >
          <Input
            placeholder="Search User"
            onChange={onChangeSearch}
            style={{ width: "22%", height: "40px", fontSize: "15px" }}
          />
        </Row>

        <CommonTable
          className={style.customerTable}
          data={bidsListData}
          fetchRecords={(page) => setPage(page)}
          totalRecord={totalNumberOfRecords}
          columns={columnsData}
        />
      </Spin>
    </div>
  );
};

export default BidsList;
