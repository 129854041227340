import client from "apis/config";

export const getDashboardAPI = async () => {
  return await client.get(`dashBoard`);
};

export const getManageAuctionsApi = async (page, status = "", search = "") => {
  return await client.get(`dashBoard/auctions?status=${status}&search=${search}&page=${page}&limit=10`);
};

export const getManageAuctionsByIdApi = async (id) => {
  return await client.get(`dashBoard/auctions/${id}`);
};

export const postAuctionApi = async (body) => {
  return await client.post("auctions", body);
};

export const postAuctionsViaCSVApi = async (body) => {
  return await client.post("auctions/csv", body);
};

export const updateAuctionApi = async (body) => {
  return await client.patch(`auctions`, body);
};

export const setAuctionFeatureApi = async (id) => {
  return await client.put(`dashBoard/auctions/feature/${id}`);
};


export const cancelAuctionApi = async (id, data) => {
  return await client.put(`dashBoard/auctions/cancelled/${id}`, data);
};


export const deleteManageAuctionsByIdApi = async (id) => {
  return await client.delete(`auctions/${id}`);
};

export const getAuctionStatusApi = async (page = 1, limit = 10) => {
  return await client.get(`auctions/history/statuses?page=${page}&limit=${limit}`);
};

export const updateAuctionStatusMarkAsCompleteApi = async (id) => {
  return await client.put(`auctions/history/statuses/${id}`);
};

export const addAuctionShipmentApi = async (data) => {
  return await client.put(`dashBoard/auctions/shipment`, data);
};
