import client from "apis/config";

export const getManageCategoriesApi = async (page) => {
  return await client.get(`categories?page=${page}&limit=10`);
};

export const getSingleCategoryApi = async (categoryId) => {
  return await client.get(`categories?categoryId=${categoryId}`);
};


export const postCategories = async (body) => {
  return await client.post("categories", body);
};

export const updateCategoriesApi = async (body) => {
  return await client.put("categories", body);
};


export const deleteCategoryApi = async (id) => {
  return await client.delete(`categories/${id}`);
};
