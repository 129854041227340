import CommonTable from "components/Tables/CommonTable";
import { Col, Form, Input, Row, Select, Spin, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { renderLoader } from "utils/helpers/helpers";
import style from "./style.module.scss";
import { ColTextCheck } from "components/export-common-components/table-columns-text-check";
import classNames from "classnames";
import CustomModal from "components/CustomModal/CustomModal";
import { renderItemDataOrEmptyNull } from "utils/common-functions";
import { getAllEmployeeUsersApi } from "apis/user";
import EditBlackIcon from "assets/img/black/edit.svg";
import CustomButton from "components/custom-button";
import { updateEmployeeUsersApi } from "apis/user";
import { successMessage } from "utils/helpers/helpers";
import { getRolesApi } from "apis/roles-and-permission";
import { addEmployeeUsersApi } from "apis/user";
import { requestErrorHandel } from "utils/helpers/helpers";
import { PlusOutlined } from "@ant-design/icons";

const EmployeeUsers = () => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const [page, setPage] = useState(1);
  const [originalUsersDataApiRes, setOriginalUsersDataApiRes] = useState(null);
  const [usersData, setUsersData] = useState(null);
  const [accountRequestRes, setAccountRequestRes] = useState(null);
  const [loading, setLoading] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [rolesListData, setRolesListData] = useState([]);
  const [totalNumberOfRecords, setTotalNumberOfRecords] = useState(0);

  const fetchDataApi = async () => {
    try {
      setLoading(true);
      const response = await getAllEmployeeUsersApi(page);
      if (response.status === 200 && response.data?.data) {
        setUsersData(response.data.data);
        setTotalNumberOfRecords(response?.data?.total);
        setAccountRequestRes(response?.data);
        setOriginalUsersDataApiRes(response?.data?.data);
      } else {
        throw response;
      }
    } catch (e) {
      console.log({ e });
    } finally {
      setLoading(false);
    }
  };

  const fetchRolesDataApi = async () => {
    try {
      setLoading(true);
      const response = await getRolesApi(page);
      if (response.status === 200 && response.data?.data) {
        setRolesListData(response.data.data);
      } else {
        throw response;
      }
    } catch (e) {
      console.log({ e });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataApi();
    fetchRolesDataApi();
  }, []);

  useEffect(() => {
    fetchDataApi();
  }, [page]);

  useEffect(() => {
    if (rowData) {
      form.setFieldsValue({
        email: rowData?.email,
        roleId: rowData?.role.id,
      });
    }
  }, [rowData]);

  const onDetailsClick = (data) => {
    setRowData(data);
    setVisibleModal(true);
  };

  const onUpdateEmployeeUser = async (data) => {
    try {
      setLoading(true);
      const response = await updateEmployeeUsersApi(data);
      if (response.status === 200 && response.data) {
        successMessage({ message: "User has been updated." });
        fetchDataApi();
        setVisibleModal(false);
      } else {
        throw response;
      }
    } catch (error) {
      requestErrorHandel({ error });
    } finally {
      setLoading(false);
    }
  };

  const onAddEmployeeUser = async (data) => {
    try {
      setLoading(true);
      const response = await addEmployeeUsersApi(data);
      if (response.status === 200 && response.data) {
        successMessage({ message: "User has been added." });
        fetchDataApi();
        setVisibleModal(false);
      } else {
        throw response;
      }
    } catch (error) {
      requestErrorHandel({ error });
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const columnsData = [
   
    {
      title: "Email",
      key: "email",
      width: "40%",
      dataIndex: "email",
      render: ColTextCheck,
    },
    {
      title: "Roles",
      key: "role",
      width: "40%",
      dataIndex: "role",
      render: (text) => {
        return (
          <>
            <p> {renderItemDataOrEmptyNull(text?.name)} </p>
          </>
        );
      },
    },
    {
      title: "Actions",
      key: "Actions",
      width: "40%",
      dataIndex: "Actions",
      render: (text, row) => {
        return (
          <div className={style.actions}>
            <div>
              <Tooltip placement="top" title={"Edit"}>
                <img
                  onClick={() => onDetailsClick(row)}
                  src={EditBlackIcon}
                  alt="icon"
                />
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  ];

  const onChangeSearch = (e) => {
    const dummyArr = originalUsersDataApiRes?.slice();
    const filteredData = dummyArr?.filter((item) =>
      (item?.name).toLowerCase()?.includes(e.target.value.toLowerCase())
    );
    if (filteredData?.length) {
      setUsersData(filteredData);
    } else if (e.target.value === "") {
      setUsersData(originalUsersDataApiRes);
    } else {
      setUsersData([]);
    }
  };

  const onSubmit = async (values) => {
    if (rowData) {
      await onUpdateEmployeeUser({
        userId: rowData?.id,
        roleId: values?.roleId,
      });
    } else {
      await onAddEmployeeUser(values);
    }
  };

  return (
    <div className={classNames(style.userListContainer, "both-side-padding")}>
      <Spin spinning={loading} indicator={renderLoader}>
        <Row justify={"end"}>
          <CustomButton
            title={"Add User"}
            onClick={() => setVisibleModal(true)}
            variant="two"
            startData={<PlusOutlined />}
          />
        </Row>
        <CommonTable
          className={style.customerTable}
          data={usersData}
          fetchRecords={(page) => setPage(page)}
          totalRecord={totalNumberOfRecords}
          columns={columnsData}
        />

        <CustomModal
          visibility={visibleModal}
          handleCancel={() => {
            setRowData(null);
            setVisibleModal(false);
          }}
          handleOk={() => null}
          title={rowData ? "Update User" : "Add New User"}
          className={style.modalEmployee}
        >
          <Spin spinning={loading} indicator={renderLoader}>
            <Form
              layout="vertical"
              className={style.modalHeading}
              form={form}
              name="control-hooks"
              onFinish={onSubmit}
            >
              <Row align="bottom" gutter={[10]}>
                <Col span={24}>
                  <Form.Item label={"Email"} name={"email"}>
                    <Input disabled={rowData} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label={"Role"} name={"roleId"}>
                    <Select>
                      {rolesListData?.map((item) => (
                        <Option value={item?.id}>
                          {renderItemDataOrEmptyNull(item?.name)}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row justify={"center"}>
                <CustomButton
                  title={(rowData && "Update User") || "Add User"}
                  disabled={loading}
                  htmlType="submit"
                  loading={loading}
                  variant="two"
                  startData={<PlusOutlined />}
                />
              </Row>
            </Form>
          </Spin>
        </CustomModal>
      </Spin>
    </div>
  );
};

export default EmployeeUsers;
