import CommonTable from "components/Tables/CommonTable";
import { Input, Row, Spin, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { renderLoader } from "utils/helpers/helpers";
import style from "./style.module.scss";
import { ColTextCheck } from "components/export-common-components/table-columns-text-check";
import classNames from "classnames";
import { getRolesApi } from "apis/roles-and-permission";
import EditBlackIcon from "assets/img/black/edit.svg";
import DeleteRedIcon from "assets/img/red/delete.svg";
import CustomButton from "components/custom-button";
import CommonDeleteModal from "components/common-delete-modal";
import { deleteRolesApi } from "apis/roles-and-permission";
import { infoMessage } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";
import { EyeOutlined, PlusOutlined } from "@ant-design/icons";

const RolesList = (props) => {
  const [originalRolesApiRes, setOriginalRolesListApiRes] = useState(null);
  const [rolesListData, setRolesListData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [rowData, setRowData] = useState(null);

  const fetchDataApi = async () => {
    try {
      setLoading(true);
      const response = await getRolesApi();
      if (response.status === 200 && response.data?.data) {
        setRolesListData(response.data.data);
        setOriginalRolesListApiRes(response?.data?.data);
      } else {
        throw response;
      }
    } catch (e) {
      requestErrorHandel({ error: e });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataApi();
  }, []);

  const columnsData = [
    {
      title: "Role",
      key: "name",
      dataIndex: "name",
      render: ColTextCheck,
    },
    {
      title: "Description",
      key: "description",
      dataIndex: "description",
      render: ColTextCheck,
    },
    {
      title: "User",
      key: "name",
      dataIndex: "name",
      render: ColTextCheck,
    },
    {
      title: "Actions",
      key: "Actions",
      dataIndex: "Actions",
      render: (text, row) => {
        return (
          <Row justify={"center"} className={style.actions}>
            <Tooltip placement="top" title={"Edit"}>
              <img
                src={EditBlackIcon}
                onClick={() => props.history.push("/admin/roles-form", row)}
                alt="icon"
              />
            </Tooltip>
            <Tooltip placement="top" title={"Delete"}>
              <img
                src={DeleteRedIcon}
                onClick={() => {
                  setRowData(row);
                  setVisibleDeleteModal(true);
                }}
                on
                alt="icon"
              />
            </Tooltip>
            <Tooltip placement="top" title={"View"}>
              <EyeOutlined
                style={{
                  color: "#899499",
                  fontSize: "15px",
                  cursor: "pointer",
                }}
                onClick={() =>
                  props.history.push("/admin/roles-form", {
                    viewOnly: true,
                    ...row,
                  })
                }
              />
            </Tooltip>
          </Row>
        );
      },
    },
  ];

  const onChangeSearch = (e) => {
    const dummyArr = originalRolesApiRes?.slice();
    const filteredData = dummyArr?.filter((item) =>
      (item?.name).toLowerCase()?.includes(e.target.value.toLowerCase())
    );
    if (filteredData?.length) {
      setRolesListData(filteredData);
    } else if (e.target.value === "") {
      setRolesListData(originalRolesApiRes);
    } else {
      setRolesListData([]);
    }
  };

  const onSuccessApi = () => {
    fetchDataApi();
    setVisibleDeleteModal(false);
    setRowData(null);
    infoMessage("Role deleted successfully");
  };

  const onConfirmDelete = async () => {
    try {
      setLoading(true);
      if (rowData) {
        const res = await deleteRolesApi(rowData?.id);
        if (res?.data?.statusCode === 200) {
          onSuccessApi();
        }
      }
      setLoading(false);
    } catch (error) {
      requestErrorHandel({ error: error });
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classNames(style.userListContainer, "both-side-padding")}>
      <Spin spinning={loading} indicator={renderLoader}>
        <CommonDeleteModal
          visibleModal={visibleDeleteModal}
          title={"Delete Role"}
          confimBtnTitle={"Confirm"}
          cancelBtnTitle="Cancel"
          loadingConfirmBtn={loading}
          onClickConfirm={onConfirmDelete}
          handleCancel={() => setVisibleDeleteModal(false)}
        />
        <Row
          justify={"end"}
          alignItem="center"
          className={style.searchBoxContainer}
        >
          <CustomButton
            onClick={() => {
              props.history.push("roles-form");
            }}
            title="Add Role"
            variant="one"
            startData={<PlusOutlined />}
          />
          <Input
            placeholder="Search Role"
            onChange={onChangeSearch}
            style={{ width: "22%", height: "40px", fontSize: "15px" }}
          />
        </Row>

        <CommonTable
          className={style.customerTable}
          data={rolesListData}
          hidePagination={true}
          columns={columnsData}
        />
      </Spin>
    </div>
  );
};

export default RolesList;
