import {
  DatePicker,
  Form,
  Input,
  Select,
  Row,
  Image,
  Col,
  Switch,
  Spin,
} from "antd";
import React, { useState } from "react";
import placeholderImage from "assets/img/default.png";
import moment from "moment";
import style from "./style.module.scss";
import TableHeader from "components/TableHeader/TableHeader";
import { successMessage, requestErrorHandel } from "utils/helpers/helpers";
import { setRules } from "utils/common-functions";
import { renderLoader } from "utils/helpers/helpers";
import { postAuctionApi } from "apis/auction/AuctionApi";
import { useEffect } from "react";
import { getGradesApi } from "apis/user";
import { renderItemDataOrEmptyNull } from "utils/common-functions";
import { getManageCategoriesApi } from "apis/manage-categories";
import CrossBlackIcon from "assets/img/black/close-circle.svg";
import { getManageAuctionsByIdApi } from "apis/auction/AuctionApi";
import { updateAuctionApi } from "apis/auction/AuctionApi";
import { goBack } from "utils/common-functions";
import { validateSelectedFile } from "utils/helpers/helpers";
import { validateSelectedVideoFile } from "utils/helpers/helpers";
import { findAuctionTranslationObj } from "utils/common-functions";
import { uploadSingleFileToAWS } from "utils/helpers/helpers";
import CustomButton from "components/custom-button";
import { findFileExtension } from "utils/common-functions";

const { Option } = Select;

const AuctionForm = (props) => {
  const { location } = props;
  const auctionData = location.state;
  const [form] = Form.useForm();
  const [uploading, setUpLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isFeature, setIsFeature] = useState(false);
  const [gradesData, setGradesData] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [fileVideo, setFileVideo] = useState("");
  const [reportFile, setReportFile] = useState("");
  const [termsFile, setTermsFile] = useState("");
  const [files, setFiles] = useState([]);
  const [singleAuctionData, setSingleAuctionData] = useState(null);
  const [isDisabledHasBids, setIsDisabledHasBids] = useState(false);

  useEffect(() => {
    if (!gradesData?.length) {
      fetchData();
    }
  }, []);

  const fetchSingleUser = async () => {
    let res = null;
    try {
      setLoading(true);
      const response = await getManageAuctionsByIdApi(auctionData?.id);
      setLoading(false);
      res = response.data?.data;
      if (response.status === 200 && response.data?.data) {
        setSingleAuctionData(response.data?.data);
        setIsDisabledHasBids(response.data?.data?.bids?.length ? true : false);
      }
    } catch (e) {
      setLoading(false);
      requestErrorHandel({ error: e });
    } finally {
      setLoading(false);
      return res;
    }
  };

  const onSetFieldsValues = (res) => {
    form.setFieldsValue({
      title: findAuctionTranslationObj(res?.auctionTranslation, "en")?.title,
      titleAr: findAuctionTranslationObj(res?.auctionTranslation, "ar")?.title,
      titleSp: findAuctionTranslationObj(res?.auctionTranslation, "sp")?.title,
      titleCh: findAuctionTranslationObj(res?.auctionTranslation, "ch")?.title,
      description: findAuctionTranslationObj(res?.auctionTranslation, "en")
        ?.description,
      termConditions: termsFile,
      categoryId: res?.categoryId,
      deliveryFee: Number(res?.deliveryFee),
      gradeId: Number(res?.grade?.id),
      lastBidPrice: res?.lastBidPrice,
      lockedStatus: res?.lockedStatus,
      minIncrement: res?.minIncrement,
      weight: Number(res?.weight),
      unitPrice: Number(res?.price) / Number(res?.totalUnits),
      price: res?.price,
      totalUnits: res?.totalUnits,
      startAt: moment(res?.startAt),
      endAt: moment(res?.endAt),
      reportFile: reportFile,
    });

    setReportFile(res?.reportFile);
    setTermsFile(res?.termConditions);
    setIsFeature(res?.isFeature);
    const videoFile = res?.gallery?.find(
      (item) => item?.fileType === "video"
    )?.file;

    const imagesGalleryFiltered = res?.gallery?.filter(
      (item) => item?.fileType === "image"
    );
    const imgGallery = [];
    imagesGalleryFiltered?.length &&
      imagesGalleryFiltered.forEach((item) => {
        imgGallery.push({
          url: item?.file,
          file: item?.file,
        });
      });
    setFiles(imgGallery);
    setFileVideo(videoFile);
  };

  useEffect(() => {
    if (auctionData) {
      fetchSingleUser().then((res) => {
        if (res) {
          onSetFieldsValues(res);
        }
      });
    }
  }, [auctionData]);

  useEffect(() => {
    form.setFieldValue({
      gradeId: gradesData[0]?.id,
    });
  }, [gradesData, form]);

  const fetchData = async () => {
    const res = await getGradesApi();
    if (res?.data?.statusCode) {
      setGradesData(res?.data?.data);
    }
    const ctgryRes = await getManageCategoriesApi(1);
    if (ctgryRes?.data?.statusCode) {
      setCategoriesData(ctgryRes?.data?.data);
    }
  };

  const renderHeader = (title) => {
    return (
      <>
        <TableHeader
          onAddNew={() => {}}
          headerTitle={title}
          headerBtnTitle={""}
          loading={false}
        />
      </>
    );
  };

  const onPayloadFilesOnCreate = () => {
    const payloadFiles = [
      {
        file: fileVideo,
        fileType: "video",
      },
    ];

    const promises = files.map((element) => {
      return onChooseFiles(element?.file).then((res) => {
        payloadFiles.push({
          file: res,
          fileType: "image",
        });
      });
    });

    return Promise.all(promises).then(() => {
      return payloadFiles;
    });
  };

  const onPayloadFilesOnUpdate = () => {
    const payloadFiles = [
      {
        file: fileVideo,
        fileType: "video",
      },
    ];

    const promises = files.map((element) => {
      if (element?.url?.includes("blob:")) {
        return onChooseFiles(element?.file).then((res) => {
          payloadFiles.push({
            file: res,
            fileType: "image",
          });
        });
      } else {
        payloadFiles.push({
          file: element?.url,
          fileType: "image",
        });
        return Promise.resolve();
      }
    });

    return Promise.all(promises).then(() => {
      setFiles(payloadFiles);
      return payloadFiles;
    });
  };

  const onSubmit = async (values) => {
    setLoading(true);
    if (singleAuctionData) {
      // updating auction

      const filesData = await onPayloadFilesOnUpdate();
      const payload = { ...values, files: filesData, isFeature };
      payload["totalUnits"] = Number(values?.totalUnits);
      payload["categoryId"] = Number(values?.categoryId);
      payload["unitPrice"] = Number(values?.unitPrice);
      payload["gradeId"] = Number(values?.gradeId) || gradesData[0]?.id;
      payload["price"] = Number(values?.price);
      payload["minIncrement"] = Number(values?.minIncrement);
      payload["deliveryFee"] = Number(values?.deliveryFee);
      payload["reportFile"] = reportFile;
      payload["termConditions"] = termsFile;
      payload["lockedStatus"] = values?.lockedStatus || "unLocked";
      payload["weight"] = Number(values?.weight);
      payload["id"] = singleAuctionData?.id;
      try {
        await updateAuctionApi(payload).then((res) => {
          if (res.status === 200) {
            goBack(res, props);
            successMessage({ message: "Auction has been updated" });
          }
        });
      } catch (error) {
        requestErrorHandel({ error });
      }
    } else {
      // creating auction

      const uploadedFilesData = await onPayloadFilesOnCreate();
      const payload = { ...values, files: uploadedFilesData, isFeature };
      payload["totalUnits"] = Number(values?.totalUnits);
      payload["categoryId"] = Number(values?.categoryId);
      payload["unitPrice"] = Number(values?.unitPrice);
      payload["gradeId"] = Number(values?.gradeId) || gradesData[0]?.id;
      payload["price"] = Number(values?.price);
      payload["minIncrement"] = Number(values?.minIncrement);
      payload["deliveryFee"] = Number(values?.deliveryFee);
      payload["reportFile"] = reportFile;
      payload["termConditions"] = termsFile;
      payload["lockedStatus"] = values?.lockedStatus || "unLocked";
      payload["weight"] = Number(values?.weight);
      await createAuction(payload);
    }
    setLoading(false);
  };

  const createAuction = async (data) => {
    let res = null;
    try {
      setLoading(true);
      const response = await postAuctionApi(data);
      setLoading(false);
      res = response;
      if (response.status === 200) {
        successMessage({ message: "Auction created successfully" });
        props.history.goBack();
      } else throw response;
    } catch (e) {
      setLoading(false);
      requestErrorHandel({ error: e });
    } finally {
      return res;
    }
  };

  const onChooseFiles = async (fileItem) => {
    let response = null;
    try {
      if (files) {
        setUpLoading(true);
        const awsFile = await uploadSingleFileToAWS(fileItem);
        let splitUrl = awsFile.url.split("?");
        splitUrl = splitUrl[0];
        response = splitUrl;
        setUpLoading(false);
      }
    } catch (error) {
      setUpLoading(false);
      requestErrorHandel({ error });
    } finally {
      setUpLoading(false);
      return response;
    }
  };

  const removeImage = (indexToRemove) => {
    setFiles((prevArray) => {
      const newArray = [...prevArray];
      newArray.splice(indexToRemove, 1);
      return newArray;
    });
  };

  const handelPickImages = async (e) => {
    let _files = [...files];
    const file = e?.target?.files[0];
    const objectUrl = URL.createObjectURL(file);
    const data = {
      url: objectUrl,
      file: file,
    };
    const isValidFile = await validateSelectedFile(e.target.files[0]);
    if (isValidFile) {
      _files.push(data);
      setFiles(_files);
    }
  };

  const onChooseFileOfVideo = async (e) => {
    try {
      if (
        e.target.files.length &&
        validateSelectedVideoFile(e.target.files[0])
      ) {
        setUpLoading(true);
        const awsFile = await uploadSingleFileToAWS(e.target.files[0]);
        let splitUrl = awsFile.url.split("?");
        splitUrl = splitUrl[0];
        setFileVideo(splitUrl);
        setUpLoading(false);
      } else {
        setFileVideo("");
      }
    } catch (error) {
      setUpLoading(false);
      requestErrorHandel({ error: e });
    }
  };

  const onChooseFileOfReport = async (e) => {
    try {
      if (e.target.files?.length) {
        setUpLoading(true);
        const awsFile = await uploadSingleFileToAWS(e.target.files[0]);
        let splitUrl = awsFile.url.split("?");
        splitUrl = splitUrl[0];
        setReportFile(splitUrl);
        setUpLoading(false);
      }
    } catch (error) {
      requestErrorHandel({ error: e });
      setUpLoading(false);
    }
  };

  const onChooseFileOfTermsConditions = async (e) => {
    try {
      if (e.target.files?.length) {
        setUpLoading(true);
        const awsFile = await uploadSingleFileToAWS(e.target.files[0]);
        let splitUrl = awsFile.url.split("?");
        splitUrl = splitUrl[0];
        setTermsFile(splitUrl);
        setUpLoading(false);
      }
    } catch (error) {
      requestErrorHandel({ error: e });
      setUpLoading(false);
    }
  };

  return (
    <div className={style?.auctionFormContainer}>
      <Spin spinning={loading || uploading} indicator={renderLoader}>
        {renderHeader(
          `${singleAuctionData ? "Update Auction" : "Create Auction"}`
        )}
        <Form
          layout="vertical"
          className={style.createAuctionForm}
          form={form}
          name="formAuction"
          onFinish={onSubmit}
          onValuesChange={(e) => console.log("valuesChagnes", e)}
        >
          <div className={style.featuredAuctionSwitch}>
            <Switch
              disabled={auctionData}
              checked={isFeature}
              onChange={(checked) => setIsFeature(checked)}
            />
            <label> Featured Auction</label>
          </div>
          <div className={style.titleFieldContainer}>
            <Form.Item label={"Title"} name={"title"} rules={setRules("Title")}>
              <Input disabled={auctionData} />
            </Form.Item>
          </div>

          <Row
            className={style.imageVideoFileContainer}
            justify="space-between"
            gutter={[20]}
          >
            <Col md={12} xs={24}>
              <br />
              <Row>
                <p style={{ marginLeft: 2 }}>Gallery</p>
              </Row>
              <Row align="bottom" gutter={20}>
                {(files?.length &&
                  files?.map((item, i) => {
                    return (
                      <Col span={6}>
                        <div className={style.multiImageContainer}>
                          <img
                            onClick={() => !isDisabledHasBids && removeImage(i)}
                            src={CrossBlackIcon}
                            className={style.closeIcon}
                            alt=""
                          />
                          <Image
                            width={"100%"}
                            height={"5rem"}
                            src={item?.url}
                          />
                        </div>
                      </Col>
                    );
                  })) || (
                  <Col span={24}>
                    <Image width={"10rem"} src={placeholderImage} />
                  </Col>
                )}
                <Col span={24}>
                  <input
                    type="file"
                    onChange={(e) => handelPickImages(e)}
                    disabled={files?.length > 100 || isDisabledHasBids }
                    name="image"
                    style={{ marginLeft: 10, cursor: "pointer" }}
                    accept="image/png, image/gif, image/jpeg"
                  />
                  <br />
                </Col>
              </Row>
            </Col>
            <Col md={12} xs={24}>
              <br />
              <Row>
                <p style={{ marginLeft: 2 }}>Upload</p>
              </Row>
              <Row align="bottom" gutter={[10]}>
                <Col>
                  <video width={"100%"} controls src={fileVideo} />
                </Col>

                <input
                  type="file"
                  onChange={(e) => onChooseFileOfVideo(e)}
                  name="image"
                  style={{ marginLeft: 10 }}
                  accept="video/mp4, video/webm, video/ogg"
                />
                <br />
              </Row>
            </Col>
          </Row>
          <Row justify="space-between" gutter={[20]}>
            <Col md={12} xs={24}>
              <Form.Item
                rules={setRules("Start Time")}
                label={"Start Date"}
                name={"startAt"}
                initialValue={moment()}
              >
                <DatePicker
                  disabled={auctionData}
                  showTime
                  style={{ width: "100%" }}
                />
              </Form.Item>

              <Form.Item
                rules={setRules("Starting Amount")}
                label={"Starting Amount"}
                name={"price"}
              >
                <Input disabled={auctionData} />
              </Form.Item>

              <Form.Item
                label={"No. of pieces"}
                rules={setRules("No. of pieces")}
                name={"totalUnits"}
              >
                <Input disabled={auctionData} />
              </Form.Item>

              <Form.Item
                label={"Weight (Kg)"}
                rules={setRules("Weight (Kg)")}
                name={"weight"}
              >
                <Input type="number" disabled={auctionData} />
              </Form.Item>
            </Col>

            <Col md={12} xs={24}>
              <Form.Item
                rules={setRules("End Time")}
                label={"End Time"}
                name={"endAt"}
                initialValue={moment()}
              >
                <DatePicker
                  showTime
                  style={{ width: "100%" }}
                />
              </Form.Item>

              {/* <Form.Item
                rules={setRules("Category")}
                label={"Category"}
                name={"categoryId"}
              >
                <Select disabled={auctionData}>
                  {categoriesData?.length > 0 &&
                    categoriesData?.map((item) => {
                      return (
                        <Option value={item?.id}>
                          {renderItemDataOrEmptyNull(item?.title)}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item> */}

              {/* <Form.Item
                label={"Lock Status"}
                name={"lockedStatus"}
                rules={setRules("Lock Status")}
              >
                <Select disabled={auctionData} defaultValue={"locked"}>
                  <Option value={"locked"}>{"Locked"}</Option>
                  <Option value={"unLocked"}>{"Unlocked"}</Option>
                  <Option value={"notApplicable"}>{"Not Applicable"}</Option>
                </Select>
              </Form.Item> */}

              <Form.Item
                rules={setRules("Min Increment")}
                label={"Minimum Increment"}
                name={"minIncrement"}
              >
                <Input disabled={auctionData} />
              </Form.Item>

              <Form.Item
                label={"Price/Kg"}
                rules={setRules("Price/Kg")}
                name={"unitPrice"}
              >
                <Input disabled={auctionData} />
              </Form.Item>

              <Form.Item
                rules={setRules("Grade")}
                label={"Grade"}
                name={"gradeId"}
              >
                <Select
                  defaultValue={gradesData[0]?.id}
                  value={gradesData[0]?.id}
                  disabled={auctionData}
                >
                  {gradesData?.length > 0 &&
                    gradesData?.map((item) => {
                      return (
                        <Option value={item?.id}>
                          {renderItemDataOrEmptyNull(item?.grade)}{" "}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={"Description"}
                name={"description"}
              >
                <Input.TextArea rows={4} 
                  disabled={isDisabledHasBids}
                />
              </Form.Item>
              <label> Terms and Conditions </label>
              <Row align="bottom" gutter={[10]}>
                <Col>
                  {termsFile?.length > 0 && findFileExtension(termsFile) === "pdf" ? (
                    <p>
                      <iframe
                        title="Terms and Conditions PDF File"
                        src={termsFile}
                        width="100%"
                        height="auto"
                      ></iframe>
                      <a
                        href={termsFile}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        View and download the PDF file
                      </a>
                      .
                    </p>
                  ) : (
                    <Image width={"10rem"} src={placeholderImage} />
                  )}
                </Col>
                <Form.Item
                  label={""}
                  name={"termConditions"}
                >
                  <input
                    type="file"
                    onChange={async (e) => onChooseFileOfTermsConditions(e)}
                    name="image"
                    defaultValue={termsFile}
                    value={termsFile}
                    style={{ marginLeft: 10 }}
                    accept="application/pdf"
                  />
                </Form.Item>
                <br />
              </Row>
            </Col>
          </Row>
          <br />
          <Row>
            <p style={{ marginLeft: 2 }}>Report file upload</p>
          </Row>
          <Row align="bottom" gutter={[10]}>
            <Col>
              {findFileExtension(reportFile) === "pdf" ? (
                <p>
                  <iframe
                    title="Report PDF File"
                    src={reportFile}
                    width="100%"
                    height="auto"
                  ></iframe>
                  <a
                    href={reportFile}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    View and download the PDF file
                  </a>
                  .
                </p>
              ) : (
                <Image
                  width={"10rem"}
                  src={reportFile.length > 0 ? reportFile : placeholderImage}
                />
              )}
            </Col>
            <Form.Item label={""} name={"reportFile"}>
              <input
                type="file"
                onChange={async (e) => onChooseFileOfReport(e)}
                name="image"
                defaultValue={reportFile}
                value={reportFile}
                style={{ marginLeft: 10 }}
                accept="application/pdf"
              />
            </Form.Item>
            <br />
          </Row>
          <Row justify="space-between" gutter={[20]}>
            <Col md={12} xs={24}>
              <Form.Item
                label={"Delivery Fees"}
                name={"deliveryFee"}
                disabled={isDisabledHasBids}
              >
                <Input
                disabled={isDisabledHasBids}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between">
            <CustomButton
              title={"Cancel"}
              style={{
                backgroundColor: "white",
                color: "black",
                border: "1px solid black",
              }}
              onClick={() => {
                goBack(null, props);
              }}
            />
            <CustomButton
              htmlType="submit"
              disabled={loading || uploading}
              loading={loading || uploading}
              variant={!singleAuctionData && "two"}
              style={{ backgroundColor: "#36454f", color: "#fff" }}
              title={
                loading
                  ? singleAuctionData
                    ? "Updating"
                    : "Saving..."
                  : uploading
                  ? "Uploading..."
                  : singleAuctionData
                  ? "Update"
                  : "Save"
              }
            />
          </Row>
        </Form>
      </Spin>
    </div>
  );
};

export default AuctionForm;
