import CommonTable from "components/Tables/CommonTable";
import { Input, Row, Spin, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { renderLoader } from "utils/helpers/helpers";
import style from "./style.module.scss";
import { ColTextCheck } from "components/export-common-components/table-columns-text-check";
import classNames from "classnames";
import { EyeOutlined } from "@ant-design/icons";
import { getAllUsersApi } from "apis/user";
import { CURRENCY_SYMBOL } from "utils/constants/const";
import { requestErrorHandel } from "utils/helpers/helpers";

const UsersList = (props) => {
  const [page, setPage] = useState(1);
  const [originalCustomersListApiRes, setOriginalCustomersListApiRes] =
    useState(null);
  const [customerListData, setCustomersListData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [totalNumberOfRecords, setTotalNumberOfRecords] = useState(0);

  const fetchDataApi = async () => {
    try {
      setLoading(true);
      const response = await getAllUsersApi(page);
      if (response.status === 200 && response.data?.data) {
        setCustomersListData(response.data.data);
        setTotalNumberOfRecords(response?.data?.total);
        setOriginalCustomersListApiRes(response?.data?.data);
      } else {
        throw response;
      }
    } catch (e) {
      requestErrorHandel({ error: e });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataApi();
  }, []);

  useEffect(() => {
    fetchDataApi();
  }, [page]);

  const columnsData = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      render: (text, row) => {
        return (
          <p
            className={style.onClickName}
            onClick={() => {
              props.history.push("customers/user-details", row);
            }}
          >
            {ColTextCheck(text)}
          </p>
        );
      },
    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
      render: ColTextCheck,
    },
    {
      title: "Phone No.",
      key: "phoneNo",
      dataIndex: "phoneNo",
      render: ColTextCheck,
    },
    {
      title: "Deposit Amount",
      key: "amount",
      dataIndex: "amount",
      render: (text) => {
        return (
          <Row className={style.amount} alignItem={"center"}>
            <p>{CURRENCY_SYMBOL}</p>
            {ColTextCheck(text)}
          </Row>
        );
      },
    },
    {
      title: "Auction Participated",
      key: "totalParticipants",
      dataIndex: "totalParticipants",
      render: ColTextCheck,
    },
    {
      title: "Actions",
      key: "Actions",
      dataIndex: "Actions",
      render: (text, row) => {
        return (
          <div
            onClick={() => {
              props.history.push("customers/user-details", row);
            }}
          >
            <Tooltip placement="top" title={"Edit"}>
              <EyeOutlined className={style.eyeIcon} />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const onChangeSearch = (e) => {
    const dummyArr = originalCustomersListApiRes?.slice();
    const filteredData = dummyArr?.filter((item) =>
      (item?.name).toLowerCase()?.includes(e.target.value.toLowerCase())
    );
    if (filteredData?.length) {
      setCustomersListData(filteredData);
    } else if (e.target.value === "") {
      setCustomersListData(originalCustomersListApiRes);
    } else {
      setCustomersListData([]);
    }
  };

  return (
    <div className={classNames(style.userListContainer, "both-side-padding")}>
      <Spin spinning={loading} indicator={renderLoader}>
        <Row
          justify={"end"}
          alignItem="center"
          className={style.searchBoxContainer}
        >
          <Input
            placeholder="Search User"
            onChange={onChangeSearch}
            style={{ width: "20%", height: "40px", fontSize: "15px" }}
          />
        </Row>

        <CommonTable
          className={style.customerTable}
          data={customerListData}
          fetchRecords={(page) => setPage(page)}
          totalRecord={totalNumberOfRecords}
          columns={columnsData}
        />
      </Spin>
    </div>
  );
};

export default UsersList;
