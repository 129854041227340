import admissionStatusIcon from "../assets/img/sibar/admissionStatusIcon.png";
import Dashboard from "views/screens/dashboard";
import { constRoute } from "utils/constants";
import DashboardRed from 'assets/img/sibar/red/dashboard.svg'
import CustomerRed from 'assets/img/sibar/red/customer.svg'
import NewAccountRequestRed from 'assets/img/sibar/red/new-account.svg'
import ManageAuctionsRed from 'assets/img/sibar/red/manage-auction.svg'
import AuctionStatusRed from 'assets/img/sibar/red/auction-status.svg'
import AllBiddingsRed from 'assets/img/sibar/red/all-biding.svg'
import ImportantAlertsRed from 'assets/img/sibar/red/important-alert.svg'
import SiteSettingRed from 'assets/img/sibar/red/site-setting.svg'
import PagesRed from 'assets/img/sibar/red/pages.svg'
import RolesRed from 'assets/img/sibar/red/roles.svg'
import UsersRed from 'assets/img/sibar/red/users.svg'

import DashboardGrey from 'assets/img/sibar/grey/dashboard.svg'
import CustomerGrey from 'assets/img/sibar/grey/customers.svg'
import NewAccountRequestGrey from 'assets/img/sibar/grey/new-auction-requirement.svg'
import ManageAuctionsGrey from 'assets/img/sibar/grey/manage-auctions.svg'
import AuctionStatusGrey from 'assets/img/sibar/grey/auction-status.svg'
import AllBiddingsGrey from 'assets/img/sibar/grey/all-biddings.svg'
import ImportantAlertsGrey from 'assets/img/sibar/grey/important-alert.svg'
import SiteSettingGrey from 'assets/img/sibar/grey/site-setting.svg'
import PagesGrey from 'assets/img/sibar/grey/pages.svg'
import RolesGrey from 'assets/img/sibar/grey/roles.svg'
import UsersGrey from 'assets/img/sibar/grey/users.svg'
import UsersList from "views/screens/users/index.jsx";
import NewAccountsRequestList from "views/screens/accounts-request";
import ManageAuctionList from "views/screens/manage-auction";
import AuctionStatusList from "views/screens/auction-status";
import BidsList from "views/screens/bids";
import RolesList from "views/screens/roles-and-permission";
import PagesList from "views/screens/Pages";
import ImportantAlerts from "views/screens/important-alerts";
import EmployeeUsers from "views/screens/employee-users";
import SiteSetting from "views/screens/Site-Setting";
import CryptoDetails from "views/screens/crypto-details";

const AdministrationRoutes = [
  {
    path: "/administration",
    name: "Administration",
    icon: admissionStatusIcon,
    component: null,
    layout: "/admin",
    subPath: true,
    type: "administration",
    subMenu: [
      {
        path: constRoute?.dashboard,
        name: "Dashbord",
        icon: DashboardRed,
        activeIcon: DashboardGrey,
        component: Dashboard,
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "Dashboard",
      },
      {
        path: constRoute?.customers,
        name: "Customers",
        icon: CustomerRed,
        activeIcon: CustomerGrey,
        component: UsersList,
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "Customers",
      },
      {
        path: constRoute?.newAccountRequest,
        name: "New Accounts Request",
        icon: NewAccountRequestRed,
        activeIcon: NewAccountRequestGrey,
        component: NewAccountsRequestList,
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "New Accounts Request",
      },
      {
        path: constRoute?.manageAuctions,
        name: "Manage Auctions",
        icon: ManageAuctionsRed,
        activeIcon: ManageAuctionsGrey,
        component: ManageAuctionList,
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "Manage Auctions",
      },
      {
        path: constRoute?.cryptoDetail,
        name: "Crypto Details",
        icon: ManageAuctionsRed,
        activeIcon: ManageAuctionsGrey,
        component: CryptoDetails,
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "Crypto Details",
      },
      {
        path: constRoute?.auctionStatus,
        name: "Auctions Status",
        icon: AuctionStatusRed,
        activeIcon: AuctionStatusGrey,
        component: AuctionStatusList,
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "Auctions Status",
      },
      {
        path: constRoute?.allBiddings,
        name: "All Biddings",
        icon: AllBiddingsRed,
        activeIcon: AllBiddingsGrey,
        component: BidsList,
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "All Biddings",
      },
      {
        path: constRoute?.importantAlerts,
        name: "Important Alerts",
        icon: ImportantAlertsRed,
        activeIcon: ImportantAlertsGrey,
        component: ImportantAlerts,
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "Important Alerts",
      },
      {
        path: constRoute?.siteSetting,
        name: "Site Settings",
        icon: SiteSettingRed,
        activeIcon: SiteSettingGrey,
        component: SiteSetting,
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "Site Settings",
      },
      {
        path: constRoute?.pages,
        name: "Pages",
        icon: PagesRed,
        activeIcon: PagesGrey,
        component: PagesList,
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "Pages",
      },
      {
        path: constRoute?.roles,
        name: "Roles",
        icon: RolesRed,
        activeIcon: RolesGrey,
        component: RolesList,
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "Roles",
      },
      {
        path: constRoute?.users,
        name: "Users",
        icon: UsersRed,
        activeIcon: UsersGrey,
        component: EmployeeUsers,
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "Users",
      }
    ],
  },
];
export default AdministrationRoutes;
