import client from "apis/config";

export const getAllUsersApi = async (page) => {
  return await client.get(`dashBoard/customers?page=${page}&limit=10`);
};

export const getSignleCustomerApi = async (id) => {
  return await client.get(`dashBoard/customers/${id}`);
};

export const getGradesApi = async () => {
  return await client.get(`grades`);
};

export const postAdminDepositApi = async (payload) => {
  return await client.post("dashBoard/deposits", payload);
};

export const getAllEmployeeUsersApi = async () => {
  return await client.get(`users`);
};


export const addEmployeeUsersApi = async (data) => {
  return await client.post(`users`, data);
};

export const updateEmployeeUsersApi = async (data) => {
  return await client.patch(`users`, data);
};