import CommonTable from "components/Tables/CommonTable";
import { Button, Spin, Tooltip } from "antd";
import React, { memo, useEffect, useState } from "react";
import { renderLoader } from "utils/helpers/helpers";
import style from "./style.module.scss";
import { ColTextCheck } from "components/export-common-components/table-columns-text-check";
import classNames from "classnames";
import { NotificationManager } from "react-notifications";
import { getAuctionStatusApi } from "apis/auction/AuctionApi";
import { requestErrorHandel } from "utils/helpers/helpers";
import { updateAuctionStatusMarkAsCompleteApi } from "apis/auction/AuctionApi";
import { EMPTY_NULL_DATA } from "utils/constants/const";
import { renderItemDataOrEmptyNull } from "utils/common-functions";

const AuctionStatusImportantAlert = () => {
  const [auctionStatusListData, setAuctionStatusListData] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchDataApi = async () => {
    try {
      setLoading(true);
      const response = await getAuctionStatusApi();
      if (response.status === 200 && response.data?.data) {
        setAuctionStatusListData(response.data.data);
      } else {
        throw response;
      }
    } catch (e) {
      requestErrorHandel({ error: e });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataApi();
  }, []);

  const markAsCompleteFun = async (id) => {
    try {
      const response = await updateAuctionStatusMarkAsCompleteApi(id);
      if (response.status === 200 && response.data) {
        NotificationManager.info(response?.data?.message);
        fetchDataApi();
      } else {
        throw response;
      }
    } catch (e) {
      requestErrorHandel({ error: e });
    }
  };

  const columnsData = [
    {
      title: "Notification",
      key: "key",
      dataIndex: "key",
      width: "20%",
      render: ColTextCheck,
    },
    {
      title: "Details",
      key: "key",
      width: "60%",
      dataIndex: "key",
      render: (text, row) => {
        return (
          <div>
            <p>
              Attention needed:{" "}
              {(text === "auctionWon" &&
                `${
                  row?.logs?.winnerName
                } has won this auction ${renderItemDataOrEmptyNull(
                  row?.logs?.auctionName
                )}`) ||
                (text === "payAuction" &&
                  `${EMPTY_NULL_DATA} has paid the ${renderItemDataOrEmptyNull(
                    row?.logs?.auctionName
                  )}`) ||
                (text === "auctionClosed" &&
                  ` has closed the ${renderItemDataOrEmptyNull(
                    row?.logs?.auctionName
                  )}`)}
            </p>
          </div>
        );
      },
    },
    {
      title: "Actions",
      key: "Actions",
      width: "20%",
      dataIndex: "Actions",
      render: (text, row) => {
        return (
          <div className={style.actions}>
            <div>
              <Tooltip placement="top" title={"Mark as complete"}>
                <Button onClick={() => markAsCompleteFun(row?.id)}>
                  Mark as complete
                </Button>
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className={classNames(style.userListContainer, "both-side-padding")}>
      <Spin spinning={loading} indicator={renderLoader}>
        <CommonTable
          className={style.customerTable}
          data={auctionStatusListData}
          hidePagination={true}
          columns={columnsData}
        />
      </Spin>
    </div>
  );
};

export default memo(AuctionStatusImportantAlert);
