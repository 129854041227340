import { Col, Row, Spin } from "antd";
import style from "./style.module.scss";
import TableHeader from "components/TableHeader/TableHeader";
import React, { useEffect, useMemo, useState } from "react";
import { renderLoader } from "utils/helpers/helpers";
import EmptyImage from "components/EmptyImage";
import CommonTable from "components/Tables/CommonTable";
import { ColTextCheck } from "components/export-common-components/table-columns-text-check";
import { renderItemDataOrEmptyNull } from "utils/common-functions";
import AuctionBlack from "assets/img/black/auction.svg";
import BidsBlack from "assets/img/black/bids.svg";
import SalesBlack from "assets/img/black/sales.svg";
import { getDashboardAPI } from "apis/auction/AuctionApi";
import { findDataOnStatus } from "utils/common-functions";
import { EMPTY_NULL_DATA } from "utils/constants/const";
import NoDataFound from "components/no-data-found";
import { renderStatusColor } from "utils/common-functions";
import { renderStatusBackgroundColor } from "utils/common-functions";
import { CURRENCY_SYMBOL } from "utils/constants/const";
import { requestErrorHandel } from "utils/helpers/helpers";

const Dashboard = (props) => {
  const [loading, setLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState(null);

  const fetchDashboardData = async () => {
    try {
      setLoading(true);
      const response = await getDashboardAPI();
      if (response.status === 200 && response.data?.data) {
        setDashboardData(response.data.data);
      } else {
        throw response;
      }
    } catch (e) {
      requestErrorHandel({ error: e });      
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (dashboardData === null) {
      fetchDashboardData();
    }
  }, []);

  const renderHeader = (title, icon) => {
    return (
      <>
        <TableHeader
          onAddNew={() => {}}
          headerTitle={title}
          headerBtnTitle={""}
          loading={loading}
          headerIcon={icon}
        />
      </>
    );
  };

  const statusCardData = [
    {
      title: "Total Auctions",
      value: renderItemDataOrEmptyNull(dashboardData?.totalAuction),
      icon: AuctionBlack,
    },
    {
      title: "Total Bids",
      value: renderItemDataOrEmptyNull(dashboardData?.totalBids),
      icon: BidsBlack,
    },
    {
      title: "Total Sales",
      value: renderItemDataOrEmptyNull(dashboardData?.totalSales),
      icon: SalesBlack,
    },
  ];

  const renderStatusCards = useMemo(() => {
    return (
      <Row gutter={20} className={style.statusRow}>
        {statusCardData?.map((item, i) => {
          return (
            <Col className={style.statusColumn} md={8} sm={24} xs={24}>
              <div className={style.statusCardContainer}>
                <div className={style.iconImgContainer}>
                  {true ? (
                    <img src={item?.icon} alt="icon" />
                  ) : (
                    <div className={style.noImage}>
                      <EmptyImage fontSize="25px" background="white" />
                    </div>
                  )}
                </div>
                <div className={style.leftBoxStatus}>
                  <div>
                    <p>{item?.title}</p>
                  </div>
                  <div>
                    <span> {renderItemDataOrEmptyNull(item?.value)} </span>
                  </div>
                </div>
              </div>
            </Col>
          );
        })}
      </Row>
    );
  }, [dashboardData]);

  const auctionTableColumn = [
    {
      title: "No. of bids",
      key: "totalBids",
      dataIndex: "totalBids",
      render: ColTextCheck,
    },
    {
      title: "Minimum Increment",
      key: "minIncrement",
      dataIndex: "minIncrement",
      render: (text) => {
        return (
          <Row className={style.amount} alignItem={"center"}>
            <p>{CURRENCY_SYMBOL}</p>
            {ColTextCheck(text)}
          </Row>
        );
      },
    },
    {
      title: "Current Price",
      key: "price",
      dataIndex: "price",
      render: (text) => {
        return (
          <Row className={style.amount} alignItem={"center"}>
            <p>{CURRENCY_SYMBOL}</p>
            {ColTextCheck(text)}
          </Row>
        );
      },
    },
    {
      title: "Unit Price",
      key: "unitPrice", 
      dataIndex: "unitPrice",
      render: (text, row) => {
        return (
          <Row className={style.amount} alignItem={"center"}>
            <p>{CURRENCY_SYMBOL}</p>
            {ColTextCheck(row?.price / row?.totalUnits)}
          </Row>
        );
      },
    },
    {
      title: "Grade",
      key: "grade",
      dataIndex: "grade",
      render: ColTextCheck,
    },
  ];

  const renderRecentAuctions = useMemo(() => {
    return (
      <div className={style.recentAuctions}>
        {renderHeader("Recent Auctions")}
        {dashboardData?.auctions?.length &&
          dashboardData?.auctions?.map((item) => {
            return (
              <div className={style.auctionCards}>
                <Row className={style.responsiveAuctions}>
                  <Col md={18} sm={18} xs={24}>
                    <div className={style.leftBox}>
                      {item?.reportFile?.length ? (
                        <img
                          src={
                            item?.files?.find(
                              (item) => item.fileType === "image"
                            )?.file
                          }
                          alt="icons"
                        />
                      ) : (
                        <div className={style.noImage}>
                          <EmptyImage fontSize="25px" />
                        </div>
                      )}
                      <div
                        onClick={() => {
                          props.history.push("auction-detail", item);
                        }}
                        className={style.titleContainer}
                      >
                        <h5> {item?.title} </h5>
                        <p>Auction ID: {item?.identifier || EMPTY_NULL_DATA}</p>
                      </div>
                    </div>
                  </Col>
                  <Col md={6} sm={6} xs={24}>
                    <div className={style.rightBox}>
                      <p className={style.noOfBids}>
                        Number of bids:{" "}
                        <span>
                          {" "}
                          {renderItemDataOrEmptyNull(item?.totalBids)}{" "}
                        </span>
                      </p>
                      <p className={style.status}>
                        Status:{" "}
                        <span
                          style={{
                            color: renderStatusColor(item?.status),
                            backgroundColor: renderStatusBackgroundColor(
                              item?.status
                            ),
                            padding: "2px 5px",
                            borderRadius: "5px",
                          }}
                        >
                          {" "}
                          {renderItemDataOrEmptyNull(item?.status)}{" "}
                        </span>
                      </p>
                    </div>
                  </Col>
                </Row>
                <div className={style.tableAuctionsContainer}>
                  <CommonTable
                    hidePagination={true}
                    columns={auctionTableColumn}
                    loading={loading}
                    data={[item]}
                  />
                </div>
              </div>
            );
          })}
      </div>
    );
  }, [dashboardData, loading]);

  const renderRecentWinners = useMemo(() => {
    return (
      <div className={style.recentWinners}>
        {renderHeader("Recent Winners")}
        {(dashboardData?.winners?.length &&
          dashboardData?.winners?.map((item) => {
            console.log("item")
            return (
              <Row className={style.recentCards}>
                <Col md={12} sm={12} xs={12}>
                  <div className={style.winnerCardLeft}>
                    <h5> {renderItemDataOrEmptyNull(item?.userName)} </h5>
                    <p
                      className={style.winnerAuctionId}
                      onClick={() => {
                        props.history.push("auction-detail", item?.auction);
                      }}
                    >
                      Auction ID: {item?.auction?.identifier}
                    </p>
                  </div>
                </Col>
                <Col md={12} sm={12} xs={12}>
                  <div className={style.winnerCardRight}>
                    <p> {findDataOnStatus(item?.status)} </p>
                    <p>{CURRENCY_SYMBOL}{renderItemDataOrEmptyNull(item?.bidPrice)}</p>
                  </div>
                </Col>
              </Row>
            );
          })) || <NoDataFound />}
      </div>
    );
  }, [dashboardData]);

  return (
    <div className={style.dashboardContaienr}>
      <Spin spinning={loading} indicator={renderLoader}>
        {renderStatusCards}
        <Row gutter={20}>
          <Col lg={14} md={12} sm={24} xs={24}>
            {renderRecentAuctions}
          </Col>
          <Col lg={10} md={12} sm={24} xs={24}>
            {renderRecentWinners}
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

export default Dashboard;
