import CommonTable from "components/Tables/CommonTable";
import {
  Col,
  Form,
  Image,
  Input,
  Row,
  Select,
  Spin,
  Switch,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { renderLoader } from "utils/helpers/helpers";
import style from "./style.module.scss";
import { ColTextCheck } from "components/export-common-components/table-columns-text-check";
import classNames from "classnames";
import { getManageAuctionsApi } from "apis/auction/AuctionApi";
import { renderItemDataOrEmptyNull } from "utils/common-functions";
import { getTimeFromOperationsWithDays } from "utils/common-functions";
import DeleteRedIcon from "assets/img/red/delete.svg";
import { capitalizeString } from "utils/common-functions";
import CustomButton from "components/custom-button";
import CustomModal from "components/CustomModal/CustomModal";
import { setAuctionFeatureApi } from "apis/auction/AuctionApi";
import * as XLSX from "xlsx";
import { successMessage } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";
import CommonDeleteModal from "components/common-delete-modal";
import { deleteManageAuctionsByIdApi } from "apis/auction/AuctionApi";
import { EyeOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { excelSerialToJSDate } from "utils/common-functions";
import { postAuctionsViaCSVApi } from "apis/auction/AuctionApi";
import { CURRENCY_SYMBOL } from "utils/constants/const";
import { Link } from "react-router-dom";
import JadeSampleFile from '../../../assets/files/sample-auction.xlsx'


const ManageAuctionList = (props) => {
  const [form] = Form.useForm();
  const [page, setPage] = useState(1);
  const [manageAuctionsListData, setManageAuctionsListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [uploading, setUpLoading] = useState(false);
  const [auctionPayloadExcel, setAuctionPayloadExcel] = useState([]);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [rowId, setRowId] = useState(null);
  const [totalNumberOfRecords, setTotalNumberOfRecords] = useState(0);
  const [filterByStatus, setFilterByStatus] = useState("");
  const [searchAuctions, setSearchAuctions] = useState("")

  const fetchDataApi = async () => {
    try {
      setLoading(true);
      setManageAuctionsListData([])
      const response = await getManageAuctionsApi(page, filterByStatus, searchAuctions);
      if (response.status === 200 && response.data?.data) {
        setManageAuctionsListData(response.data.data);
        setTotalNumberOfRecords(response.data?.total);
      } else {
        throw response;
      }
    } catch (e) {
      requestErrorHandel({ error: e });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataApi();
  }, []);

  useEffect(() => {
    fetchDataApi();
  }, [page, filterByStatus, searchAuctions]);

  const onSubmit = async (values) => {};

  const onSuccessApi = () => {
    fetchDataApi();
    setVisibleModal(false);
  };

  const onChangeAuctionFeature = async (check, row) => {
    try {
      setLoading(true);
      if (row) {
        const response = await setAuctionFeatureApi(row?.id);
        if (response.status === 200 && response.data?.data) {
          onSuccessApi();
        } else {
          throw response;
        }
      }
    } catch (e) {
      requestErrorHandel({ error: e });
    } finally {
      setLoading(false);
    }
  };

  const onReadExcelSheet = async (e) => {
    try {
      if (e.target.files.length) {
        setUpLoading(true);
        const reader = new FileReader();
        reader.onload = (event) => {
          const workbook = XLSX.read(event.target.result, { type: "binary" });
          const sheetName = workbook.SheetNames[0]; // Assuming the first sheet is the one you want to read
          const worksheet = workbook.Sheets[sheetName];
          const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          makePayloadReady(data);
        };
        reader.readAsBinaryString(e.target.files[0]);
        setUpLoading(false);
      }
    } catch (error) {
      requestErrorHandel({ error: e });
      setUpLoading(false);
    }
  };

  const columnsData = [
    {
      title: "Name",
      key: "title",
      dataIndex: "title",
      render: (text, row) => {
        return (
          <div className={style.onClickName}>
            <p
              onClick={() => {
                props.history.push("manage-auctions/create-auction", row);
              }}
            >
              {ColTextCheck(text)}
            </p>
            <span> Auction Id: {row?.identifier} </span>
          </div>
        );
      },
    },
    {
      title: "Current bid",
      key: "lastBidPrice",
      dataIndex: "lastBidPrice",
      render: (text) => {
        return (
          <Row className={style.amount} alignItem={"center"}>
            <p>{CURRENCY_SYMBOL}</p>
            {ColTextCheck(text)}
          </Row>
        );
      },
    },
    {
      title: "Minimum Increment",
      key: "minIncrement",
      dataIndex: "minIncrement",
      render: ColTextCheck,
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (text) => {
        return (
          <span> {capitalizeString(renderItemDataOrEmptyNull(text))} </span>
        );
      },
    },
    {
      title: "Bids",
      key: "totalBids",
      dataIndex: "totalBids",
      render: ColTextCheck,
    },
    {
      title: "Payment Status",
      key: "isPaid",
      dataIndex: "isPaid",
      render: (text) => {
        return (
          <dvi>
            <span> {text ? "Paid" : "Pending"} </span>
          </dvi>
        );
      },
    },
    {
      title: "Time Remaining",
      key: "email",
      dataIndex: "email",
      render: (_, row) => {
        return (
          <span>
            {renderItemDataOrEmptyNull(
              getTimeFromOperationsWithDays(row?.endAt, row?.startAt, "-")
            )}
          </span>
        );
      },
    },
    {
      title: "is featured",
      key: "isFeature",
      dataIndex: "isFeature",
      render: (text, row) => {
        return (
          <Switch
            defaultChecked={text}
            onChange={(e) => {
              onChangeAuctionFeature(e, row);
            }}
          />
        );
      },
    },
    {
      title: "Actions",
      key: "Actions",
      dataIndex: "Actions",
      render: (text, row) => {
        return (
          <Row justify={"center"} className={style.actions}>
            <Tooltip placement="top" title={"Edit"}>
              <EyeOutlined
                style={{
                  color: "#899499",
                  fontSize: "15px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  props.history.push("manage-auctions/create-auction", row);
                }}
              />
            </Tooltip>
            {row?.totalBids === 0 && (
              <Tooltip placement="top" title={"Delete"}>
                <img
                  onClick={() => {
                    setVisibleDeleteModal(true);
                    setRowId(row?.id);
                  }}
                  src={DeleteRedIcon}
                  alt="icon"
                />
              </Tooltip>
            )}
          </Row>
        );
      },
    },
  ];

  const onChangeSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchAuctions(searchValue);
  };

  const onChangeStatus = (e) => {
    const filterValue = e.toLowerCase();
    setFilterByStatus(filterValue)
  };

  const makePayloadReady = (data) => {
    const multipleAuctionPayload = [];
    data.forEach((element, index) => {
      const payload = {};
      if (index !== 0) {
        payload["title"] = element[0];
        payload["description"] = element[15] ?? null;
        payload["termConditions"] = element[16] ?? null;
        payload["startAt"] = excelSerialToJSDate(element[7]);
        payload["endAt"] = excelSerialToJSDate(element[8]);
        payload["totalUnits"] = element[11];
        payload["unitPrice"] = element[12];
        payload["weight"] = element[13];
        payload["gradeId"] = Number(element[14]);
        payload["price"] = element[9];
        payload["minIncrement"] = element[10];
        payload["reportFile"] = element[17] ?? null;
        payload["deliveryFee"] = element[18] ?? 0;
        payload["isFeature"] = element[19] ?? null;
        payload["files"] = [
          {
            file: element[1] ?? null,
            fileType: "cover",
          },
          {
            file: element[2] ?? null,
            fileType: "image",
          },
          {
            file: element[3] ?? null,
            fileType: "image",
          },
          {
            file: element[4] ?? null,
            fileType: "image",
          },
          {
            file: element[5] ?? null,
            fileType: "image",
          },
          {
            file: element[6] ?? null,
            fileType: "video",
          },
        ];
      }
      index !== 0 && multipleAuctionPayload.push(payload);
    });
    setAuctionPayloadExcel(
      multipleAuctionPayload?.filter((item) => item?.title !== undefined)
    );
  };

  const onCreateAllAuctions = () => {
    createAuctionCSVApi({ auctions: auctionPayloadExcel });
  };

  const createAuctionCSVApi = async (data) => {
    let res = null;
    try {
      setLoading(true);
      const response = await postAuctionsViaCSVApi(data);
      setLoading(false);
      res = response;
      if (response.status === 200) {
        successMessage({ message: "Auctions via excel created successfully" });
        setVisibleModal(false);
        fetchDataApi();
      } else throw response;
    } catch (e) {
      console.log({ e });
      setLoading(false);
      requestErrorHandel({ error: e });
    } finally {
      return res;
    }
  };

  const onConfirmDelete = async () => {
    try {
      setLoading(true);
      const response = await deleteManageAuctionsByIdApi(rowId);
      if (response?.data?.statusCode) {
        successMessage(response?.data);
        fetchDataApi();
        setVisibleDeleteModal(false);
      } else {
        throw response;
      }
    } catch (e) {
      requestErrorHandel({ error: e });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classNames(style.userListContainer, "both-side-padding")}>
      <Spin spinning={loading || uploading} indicator={renderLoader}>
        <CommonDeleteModal
          visibleModal={visibleDeleteModal}
          title={"Delete Auction"}
          confimBtnTitle={"Confirm"}
          cancelBtnTitle="Cancel"
          loadingConfirmBtn={loading}
          onClickConfirm={onConfirmDelete}
          handleCancel={() => setVisibleDeleteModal(false)}
        />
        <Row
          justify={"end"}
          alignItem="center"
          className={style.searchBoxContainer}
        >
          <Select
            defaultValue=""
            style={{ minWidth: 150 }}
            onChange={onChangeStatus}
            className={style.selectBox}
            options={[
              { value: "", label: "Status: All" },
              { value: "open", label: "Status: Open" },
              { value: "won", label: "Status: Won" },
              { value: "cancelled", label: "Status: Cancelled" },
              { value: "closed", label: "Status: Closed" },
              { value: "featured", label: "Featured Auctions" },
            ]}
          />
          <CustomButton
            title="Upload Auction"
            onClick={() => setVisibleModal(true)}
            variant="two"
            startData={<UploadOutlined />}
          />
          <CustomButton
            title="Create New Auction"
            onClick={() => props.history.push("manage-auctions/create-auction")}
            variant="one"
            startData={<PlusOutlined color="black" />}
          />
          <Input
            placeholder="Search Auctions"
            onChange={onChangeSearch}
            style={{ width: "15%", height: "40px", fontSize: "15px" }}
          />
        </Row>

        <CommonTable
          className={style.customerTable}
          data={manageAuctionsListData}
          fetchRecords={(page) => setPage(page)}
          totalRecord={totalNumberOfRecords}
          columns={columnsData}
        />

        <CustomModal
          visibility={visibleModal}
          handleCancel={() => {
            setVisibleModal(false);
            setAuctionPayloadExcel([]);
          }}
          handleOk={() => {}}
          className={style.modalAuctionExcel}
          title={"Upload the Receipt"}
        >
          <Form
            layout="vertical"
            className={style.auctionUploadViaExcelForm}
            form={form}
            onFinish={onSubmit}
          >
            <Row>
              <p style={{ marginLeft: 2 }}>Upload the receipt</p>
            </Row>
            <Row justify={"center"} align="center">
              <Col className={style.imgInputContainer}>
                <Col>
                  {
                    <Image
                      width={"5rem"}
                      src={
                        "https://logowik.com/content/uploads/images/microsoft-excel-new.jpg"
                      }
                    />
                  }
                </Col>

                <input
                  type="file"
                  onChange={(e) => onReadExcelSheet(e)}
                  name="image"
                  style={{ marginLeft: 10 }}
                  accept=".xlsx, .xls, application/vnd.ms-excel"
                />
                <br />
              </Col>
              <Row justify={"center"} align="center">
              <Link
                to={JadeSampleFile}
                target="_blank"
                rel="noopener noreferrer"
                download={"SampleAuctionFile.xlsx"}
              >
                <p className={style.downloadSampleFile}>Download Sample File</p>
              </Link>
            </Row>
            </Row>
            <Row>
              <Col span={24}>
                <CustomButton
                  disabled={!auctionPayloadExcel?.length}
                  className={style.btn}
                  onClick={onCreateAllAuctions}
                  loading={loading}
                  title={"Upload"}
                />
              </Col>
            </Row>
          </Form>
        </CustomModal>
      </Spin>
    </div>
  );
};

export default ManageAuctionList;
