import CommonTable from "components/Tables/CommonTable";
import { Col, Form, Image, Input, Row, Spin, Switch, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { renderLoader } from "utils/helpers/helpers";
import style from "./style.module.scss";
import { ColTextCheck } from "components/export-common-components/table-columns-text-check";
import classNames from "classnames";
import CustomModal from "components/CustomModal/CustomModal";
import CustomButton from "components/custom-button";
import { updateEmployeeUsersApi } from "apis/user";
import { successMessage } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";
import { PlusOutlined } from "@ant-design/icons";
import { EMPTY_NULL_DATA } from "utils/constants/const";
import { uploadSingleFileToAWS } from "utils/helpers/helpers";
import placeholderImage from "assets/img/default.png";
import { getCryptoDetailsApi } from "apis/crypto-details";
import { postCryptoDetailsApi } from "apis/crypto-details";
import { setEnableCryptoApi } from "apis/crypto-details";

const CryptoDetails = () => {
  const [form] = Form.useForm();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [upLoading, setUpLoading] = useState(false);
  const [qrCodeFile, setQrCodeFile] = useState()
  const [originalCryptoDataApiRes, setOriginalCryptoDataApiRes] =
    useState(null);
  const [usersData, setUsersData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [totalNumberOfRecords, setTotalNumberOfRecords] = useState(0);

  const fetchDataApi = async () => {
    try {
      setLoading(true);
      const response = await getCryptoDetailsApi(page, pageSize);
      if (response.status === 200 && response.data?.data) {
        setUsersData(response.data.data);
        setTotalNumberOfRecords(response?.data?.total);
        setOriginalCryptoDataApiRes(response?.data?.data);
      } else {
        throw response;
      }
    } catch (e) {
      console.log({ e });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataApi();
  }, []);

  useEffect(() => {
    fetchDataApi();
  }, [page, pageSize]);

  useEffect(() => {
    if (rowData) {
      form.setFieldsValue({
        email: rowData?.email,
        roleId: rowData?.role.id,
      });
    }
  }, [rowData]);

  const onDetailsClick = (data) => {
    setRowData(data);
    setVisibleModal(true);
  };

  const onUpdateEmployeeUser = async (data) => {
    try {
      setLoading(true);
      const response = await updateEmployeeUsersApi(data);
      if (response.status === 200 && response.data) {
        successMessage({ message: "Cypto details has been updated."});
        fetchDataApi();
        setVisibleModal(false);
      } else {
        throw response;
      }
    } catch (error) {
      requestErrorHandel({ error });
    } finally {
      setLoading(false);
    }
  };

  const onCryptoDetails = async (data) => {
    try {
      setLoading(true);
      const response = await postCryptoDetailsApi(data);
      if (response.status === 200 && response.data) {
        successMessage({ message: "Crypto has been created."});
        fetchDataApi();
        setVisibleModal(false);
      } else {
        throw response;
      }
    } catch (error) {
      requestErrorHandel({ error });
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

    const onChangeEnableCrypto = async (check, row) => {
      try {
        setLoading(true);
        if (row) {
          const response = await setEnableCryptoApi(row?.id);
          if (response.status === 200 && response.data?.data) {
            fetchDataApi();
            successMessage({ message: "Crypto has been enabled" })
          } else {
            throw response;
          }
        }
      } catch (e) {
        console.log({ e });
      } finally {
        setLoading(false);
      }
    };



  const onChooseFileQRCode = async (e) => {
    try {
      if (e.target.files.length) {
        setUpLoading(true);
        const awsFile = await uploadSingleFileToAWS(e.target.files[0]);
        let splitUrl = awsFile.url.split("?");
        splitUrl = splitUrl[0];
        setQrCodeFile(splitUrl);
        setUpLoading(false);
      }
    } catch (error) {
      setUpLoading(false);
    } finally {
      setUpLoading(false);
    }
  };

  ;

  const columnsData = [
    {
      title: "Wallet",
      width: "25%",
      key: "name",
      dataIndex: "name",
      render: ColTextCheck,
    },
    {
      title: "ID",
      width: "25%",
      key: "value",
      dataIndex: "value",
      render: ColTextCheck,
    },
    {
      title: "QR",
      width: "25%",
      key: "image",
      dataIndex: "image",
      render: (text) => {
        return (
          <>
            {(text && (
              <a href={text} target="_blank" rel="noopener noreferrer">
                <img width={40} src={text} alt="icon" />
              </a>
            )) || <p>{EMPTY_NULL_DATA}</p>}
          </>
        );
      },
    },
    {
      title: "Actions",
      key: "isEnable",
      width: "25%",
      dataIndex: "isEnable",
      render: (text, row) => {
        return (
          <div className={style.actions}>
            <Tooltip placement="top" title={"Enable"}>
              <Switch
                defaultChecked={text}
                disabled={text}
                onChange={(e) => {
                  onChangeEnableCrypto(e, row);
                }}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const onSubmit = async (values) => {
    if (rowData) {
      await onCryptoDetails({
        name: values?.wallet,
        value: values?.walletID,
        image: values,
      });
    } else {
      await onCryptoDetails({
        name: values?.wallet,
        value: values?.walletID,
        image: qrCodeFile,
      });
    }
  };

  return (
    <div className={classNames(style.userListContainer, "both-side-padding")}>
      <Spin spinning={loading} indicator={renderLoader}>
        <Row justify={"end"}>
          <CustomButton
            title={"Add New"}
            onClick={() => setVisibleModal(true)}
            variant="two"
            startData={<PlusOutlined />}
          />
        </Row>
        <CommonTable
          className={style.customerTable}
          data={usersData}
          fetchRecords={(page, pageSize) => {
            setPage(page)
            setPageSize(pageSize)
          }}
          totalRecord={totalNumberOfRecords}
          columns={columnsData}
          pageSize={pageSize}
        />

        <CustomModal
          visibility={visibleModal}
          handleCancel={() => {
            setRowData(null);
            setVisibleModal(false);
          }}
          handleOk={() => null}
          title={rowData ? "Update" : "Add New"}
          className={style.modalEmployee}
        >
          <Spin spinning={loading || upLoading} indicator={renderLoader}>
            <Form
              layout="vertical"
              className={style.modalHeading}
              form={form}
              name="control-hooks"
              onFinish={onSubmit}
            >
              <Row align="bottom" gutter={[10]}>
                <Col span={24}>
                  <Form.Item label={"Wallet"} name={"wallet"}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label={"Wallet ID"} name={"walletID"}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <label>QR Code</label>
                  <Col>
                    {
                      <Image
                        width={"10rem"}
                        src={qrCodeFile ?? placeholderImage}
                      />
                    }
                  </Col>
                  <input
                    type="file"
                    onChange={(e) => onChooseFileQRCode(e)}
                    name="image"
                    style={{ marginLeft: 10 }}
                    accept="image/png, image/gif, image/jpeg"
                  />
                  <br />
                </Col>
              </Row>

              <Row justify={"center"}>
                <CustomButton
                  title={(rowData && "Update") || "Add"}
                  disabled={loading}
                  htmlType="submit"
                  loading={loading}
                  variant="two"
                  width= "200px"
                  startData={<PlusOutlined />}
                />
              </Row>
            </Form>
          </Spin>
        </CustomModal>
      </Spin>
    </div>
  );
};

export default CryptoDetails;
