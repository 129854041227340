import client from "apis/config";

export const getCryptoDetailsApi = async (page, limit = 10) => {
  return await client.get(`crypto?page=${page}&limit=${limit}`);
};

export const postCryptoDetailsApi = async (data) => {
  return await client.post(`crypto`, data);
};

export const setEnableCryptoApi = async (id) => {
  return await client.patch(`crypto/enable/${id}`);
};