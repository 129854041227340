const NameMap = {
  "/admin": "",
  "/admin/dashboard": "Dashboard",
  "/admin/customers": "Customer",
  "/admin/roles": "Roles",
  "/admin/pages": "Pages",
  "/admin/important-alerts": "Important Alert",
  "/admin/customers/user-details": "User Details",
  "/admin/new-account-request": "New Account Request",
  "/admin/manage-categories": "Manage Categories",
  "/admin/manage-auctions": "Manage Auctions",
  "/admin/auction-status": "Auction Status",
  "/admin/auction-detail": "Auction Detail",
  "/admin/manage-auctions/create-auction": "Auctions Form",

};
export default NameMap;
