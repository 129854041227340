import React, { useEffect, useState } from "react";
import style from "./style.module.scss";
import { Tabs } from "antd";
import DepositRequest from "./deposit-request";
import NewAccountsRequestImportantAlert from "./accounts-request-important-alert";
import AuctionStatusImportantAlert from "./Auction-Status-important-alert";
import { requestErrorHandel } from "utils/helpers/helpers";
import { getDepositRequestApi } from "apis/important-alerts/deposit-request";
import { getNewAccountsRequestApi } from "apis/accounts-request";
import { getAuctionStatusApi } from "apis/auction/AuctionApi";

const ImportantAlerts = () => {
  const [loading, setLoading] = useState(false);
  const [depositListData, setDepositListData] = useState([]);
  const [accountRequestListData, setAccountRequestListData] = useState([])
  const [auctionStatusData, setAuctionStatusData] = useState([])

  const fetchDataDepositListApi = async () => {
    try {
      setLoading(true);
      const response = await getDepositRequestApi(1, 1000);
      if (response.status === 200 && response.data?.data) {
        setDepositListData(response.data.data);
      } else {
        throw response;
      }
    } catch (e) {
      requestErrorHandel({ error: e });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataDepositListApi();
    fetchDataAccountRequestApi();
    fetchDataAuctionStatusApi();
  }, []);

  const fetchDataAccountRequestApi = async () => {
    try {
      setLoading(true);
      const response = await getNewAccountsRequestApi(1, 1000);
      if (response.status === 200 && response.data?.data) {
        setAccountRequestListData(response.data.data);
      } else {
        throw response;
      }
    } catch (e) {
      console.log({ e });
    } finally {
      setLoading(false);
    }
  };


  const fetchDataAuctionStatusApi = async () => {
    try {
      setLoading(true);
      const response = await getAuctionStatusApi(1, 1000);
      if (response.status === 200 && response.data?.data) {
        setAuctionStatusData(response.data.data);
      } else {
        throw response;
      }
    } catch (e) {
      requestErrorHandel({ error: e });
    } finally {
      setLoading(false);
    }
  };


  const pagesItems = [
    {
      key: "1",
      label: `New Account Requests(${accountRequestListData?.length})`,
      children: <NewAccountsRequestImportantAlert />,
    },
    {
      key: "2",
      label: `Deposit Request(${depositListData?.length})`,
      children: <DepositRequest />,
    },
    {
      key: "3",
      label: `Auction Status(${auctionStatusData?.length})`,
      children: <AuctionStatusImportantAlert />,
    },
  ];

  

  const onChange = (key) => {
    console.log(key);
  };

  return (
    <div>
      <div className={style.boxContainer}>
        <div className={style.textBoxContainer}>
          <Tabs defaultActiveKey="1" items={pagesItems} onChange={onChange} />
        </div>
      </div>
    </div>
  );
};

export default ImportantAlerts;
