import CommonTable from "components/Tables/CommonTable";
import { Col, Form, Input, Row, Select, Spin, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { renderLoader } from "utils/helpers/helpers";
import style from "./style.module.scss";
import classNames from "classnames";
import EditBlackIcon from "assets/img/black/edit.svg";
import { getPagesApi } from "apis/pages";
import { renderItemDataOrEmptyNull } from "utils/common-functions";
import { requestErrorHandel } from "utils/helpers/helpers";
import CustomModal from "components/CustomModal/CustomModal";
import CustomButton from "components/custom-button";
import { updatePagesApi } from "apis/pages";
import { successMessage } from "utils/helpers/helpers";
import CommonTinyMCEEditor from "components/tiny-mce-editor";

const PagesList = () => {
  const [form] = Form.useForm();
  const { TextArea } = Input;

  const [page, setPage] = useState(1);
  const [originalPagesApiRes, setOriginalPagesListApiRes] = useState(null);
  const [pagesListData, setPagesListData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [visibleModal, setVisibleModal] = useState(false);
  const [totalNumberOfRecords, setTotalNumberOfRecords] = useState(0);

  const fetchDataApi = async () => {
    try {
      setLoading(true);
      const response = await getPagesApi(page);
      if (response.status === 200 && response.data?.data) {
        const makeArr = Object?.entries(response?.data?.data)?.map(
          ([key, value]) => {
            return {
              key: key,
              value: value,
            };
          }
        );
        setPagesListData(makeArr);
        setOriginalPagesListApiRes(makeArr);
        setTotalNumberOfRecords(response?.data?.total);
      } else {
        throw response;
      }
    } catch (e) {
      requestErrorHandel({ e });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataApi();
  }, []);

  useEffect(() => {
    fetchDataApi();
  }, [page]);

  useEffect(() => {
    form.setFieldsValue({
      type: renderItemDataOrEmptyNull(rowData?.key),
      text: rowData?.value?.valueEn,
    });
  }, [rowData]);

  const columnsData = [
    {
      title: "#",
      key: "name",
      dataIndex: "name",
      width: "20%",
      render: (text, row, index) => {
        return <span> {index + 1}</span>;
      },
    },
    {
      title: "Type",
      key: "key",
      dataIndex: "key",
      width: "60%",
      render: (text, row) => {
        return <p> {renderItemDataOrEmptyNull(row?.key)} </p>;
      },
    },
    {
      title: "Actions",
      key: "Actions",
      width: "20%",
      dataIndex: "Actions",
      render: (text, row) => {
        return (
          <div className={style.actions}>
            <div onClick={() => {}}>
              <Tooltip placement="top" title={"Edit"}>
                <img
                  onClick={() => {
                    setRowData(row);
                    setVisibleModal(true);
                  }}
                  src={EditBlackIcon}
                  alt="icon"
                />
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  ];

  const onSubmit = async (values) => {
    const payload = {
      id: rowData?.value?.id,
      name: values?.text,
    };
    try {
      setLoading(true);
      const response = await updatePagesApi(payload);
      if (response.status === 200 && response.data?.data) {
        fetchDataApi();
        successMessage({
          message: "Page Content has been edited successfully.",
        });
        setVisibleModal(false);
      } else {
        throw response;
      }
    } catch (e) {
      requestErrorHandel({ e });
    } finally {
      setLoading(false);
    }
  };

  const onChangeSearch = (e) => {
    const dummyArr = originalPagesApiRes?.slice();
    const filteredData = dummyArr?.filter((item) =>
      (item?.name).toLowerCase()?.includes(e.target.value.toLowerCase())
    );
    if (filteredData?.length) {
      setPagesListData(filteredData);
    } else if (e.target.value === "") {
      setPagesListData(setOriginalPagesListApiRes);
    } else {
      setPagesListData([]);
    }
  };

  return (
    <div className={classNames(style.userListContainer, "both-side-padding")}>
      <Spin spinning={loading} indicator={renderLoader}>
        {/* <Row
          justify={"end"}
          alignItem="center"
          className={style.searchBoxContainer}
        >
          <Input
            placeholder="Search User"
            onChange={onChangeSearch}
            style={{ width: "22%", height: "40px", fontSize: "15px" }}
          />
        </Row> */}

        <CustomModal
          visibility={visibleModal}
          handleCancel={() => {
            setRowData(null);
            setVisibleModal(false);
          }}
          handleOk={() => null}
          title={"Edit Page Content"}
        >
          <Spin spinning={loading} indicator={renderLoader}>
            <Form
              layout="vertical"
              className={style.modalForm}
              form={form}
              name="control-hooks"
              onFinish={onSubmit}
            >
              <Row align="bottom" gutter={[10]}>
                <Col span={24}>
                  <Form.Item label={"Type"} name={"type"}>
                    <Select disabled></Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label={"Text"} name={"text"}>
                    {/* <TextArea /> */}
                    <CommonTinyMCEEditor
                      value={"text"} 
                      onChange={(content) => {
                        form.setFieldsValue({ text: content }); // Update the form value
                      }}
                    />
                  </Form.Item>
                </Col>
               
                <Col span={24}>
                  <Row justify={"center"}>
                    <CustomButton
                      className={style.btn}
                      title={"Save"}
                      disabled={loading}
                      htmlType="submit"
                      loading={loading}
                      destroyOnClose={true}
                    />
                  </Row>
                </Col>
              </Row>
            </Form>
          </Spin>
        </CustomModal>

        <CommonTable
          className={style.customerTable}
          data={pagesListData}
          hidePagination={true}
          fetchRecords={(page) => setPage(page)}
          totalRecord={totalNumberOfRecords}
          columns={columnsData}
        />
      </Spin>
    </div>
  );
};

export default PagesList;
