import { Col, DatePicker, Form, Image, Input, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { renderLoader, uploadSingleFileToAWS } from "utils/helpers/helpers";
import style from "./style.module.scss";
import { renderItemDataOrEmptyNull } from "utils/common-functions";
import PlusSignRed from "assets/img/red/add-red-icon.svg";
import AttachmentIcon from "assets/img/attachment.png";
import { getSignleCustomerApi } from "apis/user";
import { formatDate } from "utils/common-functions";
import NoDataFound from "components/no-data-found";
import CustomModal from "components/CustomModal/CustomModal";
import placeholderImage from "assets/img/default.png";
import { setRules } from "utils/common-functions";
import moment from "moment";
import CustomButton from "components/custom-button";
import { postAdminDepositApi } from "apis/user";
import { EMPTY_NULL_DATA } from "utils/constants/const";
import { getTimeFromdateString } from "utils/common-functions";
import { PlusOutlined } from "@ant-design/icons";
import { updateAuctionActionApi } from "apis/accounts-request";
import { NotificationManager } from "react-notifications";
import { CURRENCY_SYMBOL } from "utils/constants/const";
import { requestErrorHandel } from "utils/helpers/helpers";

const UserDetails = (props) => {
  const [form] = Form.useForm();
  const { location } = props;
  const userData = location.state;
  const [singleCustomerData, setSingleCustomerData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [uploading, setUpLoading] = useState(false);
  const [file, setFile] = useState();

  const fetchDataApi = async () => {
    try {
      setLoading(true);
      const response = await getSignleCustomerApi(userData?.id);
      if (response.status === 200 && response.data?.data) {
        setSingleCustomerData(response.data.data);
      } else {
        throw response;
      }
    } catch (e) {
      requestErrorHandel({ error: e });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataApi();
  }, [userData?.id]);

  const onSubmit = async (values) => {
    const payload = { ...values, receipt: file, userId: userData?.id };
    payload["amount"] = Number(values?.amount);
    try {
      setLoading(true);
      await postAdminDepositApi(payload).then(async (res) => {
        if (res?.data?.statusCode) {
          await fetchDataApi();
          setVisibleModal(false);
        }
      });
    } catch (e) {
      setLoading(false);
      requestErrorHandel({ error: e });
    } finally {
      setLoading(false);
    }
  };

  const showModalClick = () => {
    setVisibleModal(true);
  };

  const onChooseFile = async (e) => {
    try {
      if (e.target.files.length) {
        setUpLoading(true);
        const awsFile = await uploadSingleFileToAWS(e.target.files[0]);
        let splitUrl = awsFile.url.split("?");
        splitUrl = splitUrl[0];
        setFile(splitUrl);
        setUpLoading(false);
      }
    } catch (error) {
      setUpLoading(false);
      requestErrorHandel({ error: e });
    }
  };

  const renderUserInfo = () => {
    return (
      <Row className={style.userInfoRow} gutter={20}>
        <Col md={14} sm={24} xs={24} className={style.userInfoCol}>
          <div className={style.cardContainer}>
            <Row style={{ padding: "10px 0px" }}>
              <Col className={style.leftUserInfoCol} sm={18} xs={24}>
                <Row className={style.leftUserInfo} justify={"start"}>
                  <Col md={8} sm={12} xs={24}>
                    <Row className={style.valueRow}>
                      <h5>Name</h5>
                    </Row>
                  </Col>
                  <Col md={8} sm={12} xs={24}>
                    <Row className={style.valueRow}>
                      <p>
                        {renderItemDataOrEmptyNull(singleCustomerData?.name)}
                      </p>
                    </Row>
                  </Col>
                </Row>

                <Row className={style.leftUserInfo} justify={"start"}>
                  <Col md={8} sm={12} xs={24}>
                    <Row className={style.valueRow}>
                      <h5>Email</h5>
                    </Row>
                  </Col>
                  <Col md={8} sm={12} xs={24}>
                    <Row className={style.valueRow}>
                      <p>
                        {renderItemDataOrEmptyNull(singleCustomerData?.email)}
                      </p>
                    </Row>
                  </Col>
                </Row>
                <Row className={style.leftUserInfo} justify={"start"}>
                  <Col md={8} sm={12} xs={24}>
                    <Row className={style.valueRow}>
                      <h5>Phone No.</h5>
                    </Row>
                  </Col>
                  <Col md={8} sm={12} xs={24}>
                    <Row className={style.valueRow}>
                      <p>
                        {renderItemDataOrEmptyNull(singleCustomerData?.phoneNo)}
                      </p>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col className={style.rightUserInfoCol} sm={6} xs={24}>
                <Row className={style.countryValueRow}>
                  <h5 style={{ paddingRight: "10px" }}>Country: </h5>
                  <p>
                    {" "}
                    {renderItemDataOrEmptyNull(
                      singleCustomerData?.country?.name
                    )}{" "}
                  </p>
                </Row>
                <Row className={style.addressUserInfoRight}>
                  <Col md={24}>
                    <h5>Business Address:</h5>
                  </Col>
                  <Col span={24}>
                    <p>
                      {singleCustomerData?.addresses?.length > 0
                        ? renderItemDataOrEmptyNull(
                            singleCustomerData?.addresses[0]?.addressLine1 +
                              ", " +
                              singleCustomerData?.addresses[0]?.addressLine2
                          )
                        : EMPTY_NULL_DATA}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
        <Col md={10} xs={24} sm={24} className={style.auctionParticipatedCol}>
          <div className={style.cardContainer}>
            <Row className={style.aucPartHeader}>
              <Col>
                <h4>Auction Participated</h4>
              </Col>
              <Col>
                <h4>
                  {renderItemDataOrEmptyNull(
                    singleCustomerData?.auctions?.length
                  )}
                </h4>
              </Col>
            </Row>
            {(singleCustomerData?.auctions?.length > 0 &&
              singleCustomerData?.auctions?.map((item) => {
                return (
                  <Row
                    className={style.aucPartValuesContainer}
                    justify={"space-between"}
                  >
                    <Col span={18}>
                      <h5> {renderItemDataOrEmptyNull(item?.title)} </h5>
                    </Col>
                    <Col span={6}>
                      <p>{renderItemDataOrEmptyNull(formatDate(item?.date))}</p>
                    </Col>
                  </Row>
                );
              })) || <NoDataFound height={100} />}
          </div>
        </Col>
      </Row>
    );
  };

  const depositManagementCard = () => {
    return (
      <div className={style.depostManagementCard}>
        <Row className={style.depositManagementHeader}>
          <Col>
            <h4>Deposit Management</h4>
          </Col>
          <Col className={style.depositBtnCol}>
            <h4>Add Deposit Amount</h4>
            <img
              onClick={() => showModalClick()}
              src={PlusSignRed}
              alt=""
              width={25}
            />
          </Col>
        </Row>
        <Row className={style.managementValues}>
          <Col md={8} sm={24} xs={24}>
            <h5>Current Depost</h5>
            <p>
              {CURRENCY_SYMBOL}{renderItemDataOrEmptyNull(singleCustomerData?.currentBalance)}
            </p>
          </Col>
          <Col md={8} sm={24} xs={24}>
            <h5>Bidding Limit</h5>
            <p>
              {CURRENCY_SYMBOL}{renderItemDataOrEmptyNull(singleCustomerData?.totalBalance)}
            </p>
          </Col>
          <Col md={8} sm={24} xs={24}>
            <h5>Available Limit</h5>
            <p>
              {CURRENCY_SYMBOL}{renderItemDataOrEmptyNull(singleCustomerData?.availableBalance)}
            </p>
          </Col>
        </Row>
      </div>
    );
  };

  const renderHistory = () => {
    return (
      <div className={style.historyContainer}>
        <Row className={style.historyHeader}>
          <h4>History</h4>
        </Row>
        <div className={style.historybody}>
          {singleCustomerData?.history
            ?.filter((item) => item?.key !== "deposit")
            ?.map((item) => {
              return (
                <Row className={style.historyItemRow}>
                  <Col md={16} className={style.historyTitle}>
                    {/* <h5>{item?.key} Rehan Ahmed won the auction</h5> */}
                    <p>
                      <b>{item.key}: </b>{" "}
                      {(item?.key === "auctionWon" &&
                        `${
                          item?.logs?.winnerName
                        } has won this auction ${renderItemDataOrEmptyNull(
                          item?.logs?.auctionName
                        )}`) ||
                        (item?.key === "payAuction" &&
                          `${EMPTY_NULL_DATA} has paid the ${renderItemDataOrEmptyNull(
                            item?.logs?.auctionName
                          )}`) ||
                        (item?.key === "auctionClosed" &&
                          ` has closed the ${renderItemDataOrEmptyNull(
                            item?.logs?.auctionName
                          )}`)}
                    </p>
                  </Col>
                  <Col md={8} className={style.historyDates}>
                    <p>{getTimeFromdateString(item?.logs?.date)} </p>
                    <p>{formatDate(item?.logs?.date)}</p>
                  </Col>
                </Row>
              );
            })}

          {singleCustomerData?.history
            ?.filter((item) => item.key === "deposit")
            ?.map((item) => {
              return (
                <Row className={style.historyItemRow}>
                  <Col md={16} className={style.historyTitle}>
                    <p>
                      {item?.logs?.name} updated deposit from {CURRENCY_SYMBOL}
                      {item?.logs?.availableAmount} to {CURRENCY_SYMBOL}
                      {item?.logs?.currentAmount}
                    </p>
                    <p>
                      - {(item?.logs?.isUserInitiatedBy && "User ") || "Admin "}
                      Initiated
                    </p>
                  </Col>
                  <Col md={8} className={style.historyDates}>
                    <p>{getTimeFromdateString(item?.logs?.date)} </p>
                    <p>{formatDate(item?.logs?.date)}</p>
                    <p className={style.viewAttachment}>
                      {" "}
                      <span>
                        {" "}
                        <img src={AttachmentIcon} width={20} alt="icon" />{" "}
                      </span>{" "}
                      <a href={item?.logs?.attachment} target="_bank">
                        {" "}
                        View Attachment
                      </a>
                    </p>
                  </Col>
                </Row>
              );
            })}
        </div>
      </div>
    );
  };

  const onUpdateAuctionAction = async (id, action) => {
    try {
      const payload = { userId: id, action };
      const response = await updateAuctionActionApi(payload);
      if (response.status === 200 && response.data) {
        NotificationManager.info(response?.data?.message);
        fetchDataApi();
      } else {
        throw response;
      }
    } catch (e) {
      requestErrorHandel({ error: e });
    }
  };

  const enableDisableAccount = () => {
    if (singleCustomerData?.accountStatus === "approved") {
      onUpdateAuctionAction(singleCustomerData?.id, "blocked");
    } else {
      onUpdateAuctionAction(singleCustomerData?.id, "approved");
    }
  };


  return (
    <div className={style.pageContainer}>
      <Spin spinning={loading} indicator={renderLoader}>
        <Row gutter={40} justify={"end"} className={style.headerbtnContainer}>
          <CustomButton
            className={style.disabledButton}
            variant={singleCustomerData?.accountStatus === "blocked" ? "one" : "three"}
            title={
              singleCustomerData?.accountStatus === "blocked"
                ? "Enable Account"
                : "Disable Account"
            }
            onClick={enableDisableAccount}
          />
          <CustomButton
            title={"Add Deposit Amount"}
            onClick={() => showModalClick()}
            variant="two"
            startData={<PlusOutlined color="black" />}
          />
        </Row>
        {renderUserInfo()}
        {depositManagementCard()}
        {renderHistory()}
        <CustomModal
          visibility={visibleModal}
          handleCancel={() => setVisibleModal(false)}
          handleOk={() => null}
          title={"Upload the Receipt"}
          className={style.uploadRecieptModal}
        >
          <Spin spinning={uploading} indicator={renderLoader}>
            <Form
              layout="vertical"
              className={style.userDetailsModalForm}
              form={form}
              onFinish={onSubmit}
            >
              <Row align="bottom" gutter={[10]}>
                <Col span={24}>
                  <Col>
                    {<Image width={"10rem"} src={file ?? placeholderImage} />}
                  </Col>
                  <input
                    type="file"
                    onChange={(e) => onChooseFile(e)}
                    name="image"
                    required
                    style={{ marginLeft: 10 }}
                    accept="image/png, image/gif, image/jpeg"
                  />
                  <br />
                </Col>
                <Col span={24}>
                  <Form.Item
                    rules={setRules("Amount")}
                    label={`Amount (${CURRENCY_SYMBOL})`}
                    name={"amount"}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    rules={setRules("Date")}
                    label={"Date"}
                    name={"date"}
                    initialValue={moment()}
                  >
                    <DatePicker style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Row justify={"center"}>
                    <CustomButton
                      loading={loading || uploading}
                      disabled={uploading}
                      className={style.btn}
                      htmlType="submit"
                      title={"Upload"}
                    />
                  </Row>
                </Col>
              </Row>
            </Form>
          </Spin>
        </CustomModal>
      </Spin>
    </div>
  );
};

export default UserDetails;
