import CommonTable from "components/Tables/CommonTable";
import { Input, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { renderLoader } from "utils/helpers/helpers";
import style from "./style.module.scss";
import { ColTextCheck } from "components/export-common-components/table-columns-text-check";
import classNames from "classnames";
import { getTimeFromOperationsWithDays } from "utils/common-functions";
import { renderItemDataOrEmptyNull } from "utils/common-functions";
import { getManageAuctionsApi } from "apis/auction/AuctionApi";
import { capitalizeString } from "utils/common-functions";
import { EyeOutlined } from "@ant-design/icons";
import { CURRENCY_SYMBOL } from "utils/constants/const";

const AuctionStatusList = (props) => {
  const [page, setPage] = useState(1);
  const [originalAuctionStatusListApiRes, setOriginalAuctionStatusListApiRes] =
    useState(null);
  const [auctionStatusListData, setAuctionStatusListData] = useState(null);
  const [loading, setLoading] = useState(false);
  
  const fetchDataApi = async () => {
    try {
      setLoading(true);
      const response = await getManageAuctionsApi(page);
      if (response.status === 200 && response.data?.data) {
        setAuctionStatusListData(response.data.data);
        setOriginalAuctionStatusListApiRes(response?.data?.data);
      } else {
        throw response;
      }
    } catch (e) {
      console.log({ e });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!auctionStatusListData) {
      fetchDataApi();
    }
  }, []);

  const columnsData = [
    {
      title: "Auction",
      key: "title",
      dataIndex: "title",
      render: (text, row) => {
        return (
          <div
            onClick={() => {
              props.history.push("auction-detail", row?.id);
            }}
            className={style.onClickName}
          >
            <p>{ColTextCheck(text)}</p>
            <span> Auction Id: {row?.identifier} </span>
          </div>
        );
      },
    },
    {
      title: "Bidding Amount",
      key: "lastBidPrice",
      dataIndex: "lastBidPrice",
      render: (text) => {
        return (
          <Row className={style.amount} alignItem={"center"}>
            <p>{CURRENCY_SYMBOL}</p>
            {ColTextCheck(text)}
          </Row>
        );
      },
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (text) => {
        return (
          <span> {capitalizeString(renderItemDataOrEmptyNull(text))} </span>
        );
      },
    },
    {
      title: "Time Remaining",
      key: "email",
      dataIndex: "email",
      render: (_, row) => {
        return (
          <span>
            {renderItemDataOrEmptyNull(
              getTimeFromOperationsWithDays(row?.endAt, row?.startAt, "-")
            )}
          </span>
        );
      },
    },
    {
      title: "Actions",
      key: "Actions",
      dataIndex: "Actions",
      render: (text, row) => {
        return (
          <Row className={style.actions}>
            <EyeOutlined
              style={{ color: "#899499", fontSize: "15px", cursor: "pointer" }}
              onClick={() => {
                props.history.push("auction-detail", row?.id);
              }}
            />
          </Row>
        );
      },
    },
  ];

  const onChangeSearch = (e) => {
    const dummyArr = originalAuctionStatusListApiRes?.slice();
    const filteredData = dummyArr?.filter((item) =>
      (item?.title)?.toLowerCase()?.includes(e?.target.value.toLowerCase())
    );
    if (filteredData?.length) {
      setAuctionStatusListData(filteredData);
    } else if (e.target.value === "") {
      setAuctionStatusListData(originalAuctionStatusListApiRes);
    } else {
      setAuctionStatusListData([]);
    }
  };

  return (
    <div className={classNames(style.userListContainer, "both-side-padding")}>
      <Spin spinning={loading} indicator={renderLoader}>
        <Row
          justify={"end"}
          alignItem="center"
          className={style.searchBoxContainer}
        >
          <Input
            placeholder="Search Auction Status"
            onChange={onChangeSearch}
            style={{ width: "22%", height: "40px", fontSize: "15px" }}
          />
        </Row>

        <CommonTable
          className={style.customerTable}
          data={auctionStatusListData}
          fetchRecords={(page) => setPage(page)}
          totalRecord={auctionStatusListData?.length}
          columns={columnsData}
        />
      </Spin>
    </div>
  );
};

export default AuctionStatusList;
