import client from "apis/config";

export const getAllBidsApi = async (page, limit = "10") => {
  return await client.get(`dashBoard/bids?page=${page}&limit=${limit}`);
};


export const postSetWinnerApi = async (data) => {
  return await client.post(`auctions/winner/decide`, data);
};
